<template>
  <div id="#login" ref="container" class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="h-12 mx-auto w-auto" src="@/assets/logo.png" alt="Accountant Chain">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <div class="-space-y-px">
        <div class="rounded-md shadow-sm">
          <div>
            <label for="email" class="sr-only">Email</label>
            <input id="email"
                   v-model="email"
                   ref="emailInput"
                   name="email"
                   type="email"
                   required
                   :class="showEmailLoginError ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-300 placeholder-red-500 text-red-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm' 
                    :'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'"
                   placeholder="Email"
                   @click="toggleEmailError">
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <div class="relative rounded-md shadow-sm">
              <input id="password"
                     v-model="password"
                     ref="passwordInput"
                     name="password"
                     type="password"
                     required
                     :class="showPasswordLoginError ? 'relative block w-full px-3 py-2 border border-red-300 placeholder-red-500 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm' 
                     :'relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'"
                     placeholder="Password"
                     @click="togglePasswordError">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                <svg v-if="!showPassword" v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                <svg v-else v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div v-if="showLoginError" class="bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-5 ">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-red-400"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-red-600"> Credentials Don't Match </p>
                <!---->
                <p class="mt-1 text-sm text-red-600"> Please try again </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                
                </div>
              </div>
            </div>
          </div>
        <div v-if="error.email !== null || error.password !== null" class="error text-red-600 mt-2">
          <span v-if="error.email">{{error.email}}</span> <br>
          <span v-if="error.password">{{error.password}}</span>
        </div>
      </div>
      <div>
        <button v-if="isValidEmail && isValidPassword && !loading" v-on:click="authenticate" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="h-5 w-5 text-blue-100 group-hover:text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
        <button v-else-if="isValidEmail && isValidPassword && loading" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          Sign in
        </button>
        <button v-else class="group cursor-not-allowed relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-300">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>        
        <div class="items-center mt-6">
          <div class="text-sm text-center">
            <router-link :to="{name: 'ForgotPassword'}" class="font-medium text-blue-500 hover:text-blue-600">
              Forgot your password?
            </router-link>
          </div>
          <div class="text-sm mt-2 text-center">
            <router-link :to="{name: 'Homepage'}" class="font-medium text-blue-500 hover:text-blue-600">
              Back to homepage
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AuthenticateService from "@/service/authenticate";

export default Vue.extend({
  name: "Login.vue",
  data: () => ({
    loading: false,
    email: '',
    password: '',
    isValidEmail: true,
    isValidPassword: false,
    showPassword: false,
    error: {
      email: null,
      password: null
    },
    showLoginError: false,
    showEmailLoginError: false,
    showPasswordLoginError: false,
  }),
  beforeMount: function() {
    if (this.$route.query) {
      const status = this.$route.query.status;
      if (status === 'success') {
        this.$notifications(
            'Your Account Is Created',
            'You can sign in now',
            '',
            0,
            true);
      }
    }
  },
  mounted: function() {
    this.$refs.container.click();
  },
  methods: {
    toggleEmailError: async function(){
      if(this.showEmailLoginError)
      {
        this.showEmailLoginError = false;
        if(this.showLoginError && !this.showPasswordLoginError)
          this.showLoginError = false;
      }
    },
    togglePasswordError: async function(){
      if(this.showPasswordLoginError)
        this.showPasswordLoginError = false;      
      if(this.showLoginError && !this.showEmailLoginError)
        this.showLoginError = false;
    },
    authenticate: async function() {
      this.loading = true;
      this.showLoginError = false;
      const dto = {
        email: this.email.toLowerCase(),
        password: this.password
      }

      const service = new AuthenticateService();
      await service.authenticate(dto)
        .then((response) => {
          this.$store.state.bearerToken = response.data.jwtToken;
          this.$store.state.refreshToken = response.data.refreshToken;
          this.$store.state.authenticated = true;
          this.$store.state.firstSignIn = true;
          localStorage.setItem('accountant_bearer', response.data.jwtToken);
          localStorage.setItem('accountant_refresh', response.data.refreshToken);
          this.$root.$emit('UserSignedIn');
          this.$root.$emit('SetAuthentication', true);
          this.$router.push({name: 'Receipt'});
        }).catch((e) => {
          console.log(e.request)
          if (e.request && e.request.response) {
            this.showLoginError = true;   
            this.showEmailLoginError = true;
            this.showPasswordLoginError = true;         
          } else {
            this.$notifications(
                'Internal Server Error',
                `Something went wrong logging in. Status code: 500`,
                '',
                1,
                true
            );
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    togglePasswordType: function() {
      this.showPassword = !this.showPassword;
      const type = document.getElementById('password').getAttribute('type');
      if (type === 'password')
        document.getElementById('password').setAttribute('type', 'text');
      else
        document.getElementById('password').setAttribute('type', 'password');
    },
  },
  watch: {
    password: {
      handler: function() {
        /**
         * Password validation RegEx for JavaScript
         *
         * Passwords must be
         * - At least 8 characters long, max length anything
         * - Include at least 1 lowercase letter
         * - 1 capital letter
         * - 1 number
         * - 1 special character => !@#$%^&*{}();',.<>?~`+_-
         *
         *
         */
        const passwordIsValid = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*{}();',.<>?~`+_-])[\w!@#$%^&*{}();',.<>?~`+_-]{8,}$/.test(this.password);
        if (this.password.length > 4 && !passwordIsValid) {
          this.error.password = null
        } else {
          this.error.password = null;
          this.isValidPassword = true;
        }
      }
    },
  }
});
</script>
