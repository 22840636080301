import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/index.css'
import '@/assets/stylen.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IsBusy from '@/plugins/IsBusy.plugin';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Notification from '@/plugins/Notification.plugin';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Environment from '@/plugins/Environment.plugin';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Conversion from '@/plugins/ExchangeAPI.plugin';

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCz2Z02YPCKQDx-s3bpEFvCcYHQmhvwfPo',
    libraries: 'places',
  }
});

Vue.use(IsBusy);
Vue.use(Notification)
Vue.use(Environment)
Vue.use(Conversion)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

