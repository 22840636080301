<template>

  <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
    <div class="w-full h-24 border-2 rounded-md mx-auto">
      <div class="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div class="w-12 ml-4 bg-gray-300 h-12 rounded-full "></div>
        <div class="flex flex-1 space-y-3">
          <div class="block">
            <div class="w-36 bg-gray-300 h-4 rounded-md "></div>
            <div class="w-6 bg-gray-300 h-4 mt-2 rounded-md "></div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-24 border-2 rounded-md mx-auto">
      <div class="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div class="w-12 ml-4 bg-gray-300 h-12 rounded-full "></div>
        <div class="flex flex-1 space-y-3">
          <div class="block">
            <div class="w-36 bg-gray-300 h-4 rounded-md "></div>
            <div class="w-6 bg-gray-300 h-4 mt-2 rounded-md ">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-24 border-2 rounded-md mx-auto">
      <div class="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div class="w-12 ml-4 bg-gray-300 h-12 rounded-full ">
        </div>
        <div class="flex flex-1 space-y-3">
          <div class="block">
            <div class="w-36 bg-gray-300 h-4 rounded-md ">
            </div>
            <div class="w-6 bg-gray-300 h-4 mt-2 rounded-md ">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
name: "Cards"
}
</script>

<style scoped>

</style>
