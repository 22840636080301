<template>
  <!-- Static sidebar for desktop -->
  <div>
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow bg-white pt-5 pb-4 overflow-y-auto">
        <nav class="mt-5 flex-1 flex flex-col divide-y divide-purple-300 overflow-y-auto" aria-label="Sidebar">
          <div class="px-2 space-y-1">
            <span v-on:click="goToPage('EmployeeCreate')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'EmployeeCreate')}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path>
                  </svg>
              Add Employee
            </span>

            <span v-on:click="goToPage('Employees')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Employees')}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path>
                  </svg>
              Employees
            </span>

            <span v-on:click="goToPage('TxHashCreate')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'TxHashCreate')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
              </svg>
              Add Tx Hash
            </span>

            <span v-on:click="goToPage('TxHash')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'TxHash')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
              </svg>
              Tx Hashes
            </span>

<!--            <span v-on:click="goToPage('PaymentCreate')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"-->
<!--                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'PaymentCreate')}">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />-->
<!--              </svg>-->
<!--              Add Payment-->
<!--            </span>-->

<!--            <span v-on:click="goToPage('Payment')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"-->
<!--                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Payment')}">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />-->
<!--              </svg>-->
<!--              Payment-->
<!--            </span>-->

            <span v-on:click="goToPage('ReceiptCreate')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ReceiptCreate')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              Add Receipts
            </span>

            <span v-on:click="goToPage('Receipt')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Receipt')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
              All Receipts
            </span>

            <span v-on:click="goToPage('ReceiptAssets')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ReceiptAssets')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
              Asset Receipts
            </span>

            <span v-on:click="goToPage('ReceiptExpenses')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ReceiptExpenses')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
              Expense Receipts
            </span>

<!--            <span v-on:click="goToPage('ExpensesHeadsCreate')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"-->
<!--                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ExpensesHeadsCreate')}">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />-->
<!--              </svg>-->
<!--              Add Expense-->
<!--            </span>-->

            <span v-on:click="goToPage('ExpensesHeads')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ExpensesHeads')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Expenses
            </span>

            <span v-on:click="goToPage('ExpensesCategories')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'ExpensesCategories')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Expense Categories
            </span>

            <span v-on:click="goToPage('Vendors')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Vendors')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              Vendors
            </span>

            <span v-on:click="goToPage('Business')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Business')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Companies
            </span>

            <span v-on:click="goToPage('Profile')" class="cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-200 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  v-bind:class="{'bg-gray-200': $route.matched.some(({ name }) => name === 'Profile')}">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Profile
            </span>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
name: "Sidebar.desktop.vue",
  data: () => ({
    isStaff: store.getters.user.is_staff
  }),
  methods: {
    goToPage: function(page) {
      this.$root.$emit('EmptySearchResult');
      this.$router.push({name: page});
    }
  }
}
</script>

<style scoped>
.router-link-exact-active {
  background-color: #F3F4F6;
}
</style>

