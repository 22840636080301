<template>
  <div>
    <h3 class="text-3xl leading-6 font-bold text-gray-900 flex-1 pt-6">
      Items
    </h3>
    <div v-for="(item, i) in items" :key="i" class="mt-4 block">
      <div class="p-4 bg-gray-50 rounded-lg">
        <div>
          <div class="block text-purple-500">
            Description
          </div>
          <div class="block mt-2">
            <p class="text-lg">
              {{ item.description }}
            </p>
          </div>
        </div>
        <div v-if="item.amount" class="block mt-4">
          <div class="block text-purple-500">
            Amount Paid
          </div>
          <div class="block mt-2">
            <h3 class="text-lg">
              {{ formatDollar(item.amount) }}
            </h3>
          </div>
        </div>
        <div v-if="item.type === 1 && item.previousValuation" class="block mt-4">
          <div class="block text-purple-500">
            Previous Valuation
          </div>
          <div class="block mt-2">
            <h3 class="text-lg">
              {{ formatDollar(item.previousValuation) }}
            </h3>
          </div>
        </div>
        <div v-if="item.type === 1" class="block mt-4">
          <div class="block text-purple-500">
            Valuation
          </div>          
          <div class="p-4 bg-gray-200 rounded-lg">
            <div class="block text-purple-500">
              Amount
            </div>
            <div class="block mt-2">
              <h3 class="text-lg">
                {{ formatDollar(item.valuation) }} 
                <span v-bind:class = "(item.valuationDifferencePercentage > 0)?'text-green':'text-red'" v-if="item.valuationDifferencePercentage"> ({{item.valuationDifferencePercentage > 0 ? '+' : '' }}{{item.valuationDifferencePercentage.toFixed(2)}}%)</span>
              </h3>
            </div>

            <div class="block text-purple-500">
              Description
            </div>
            <div class="block mt-2">
              <h3 class="text-lg">
                {{ item.valuationDescription }}
              </h3>
            </div>

            <div v-if="item.valuationFiles.length > 0" class="block p-4 bg-gray-300 rounded-lg" >
            <div class="block text-purple-500">
              Files
            </div>
            <div class="block mt-2">
              <div class="flex overflow-x-auto">
                <div v-for="(file, key) in item.valuationFiles" :key="key">
                  <div class="block text-black-800">
                      {{file.name}}
                  </div>
                  <div class="w-56 h-36 bg-cover mr-5 rounded-md">                    
                    <img :src="file.url"
                         v-on:click="toggleModal(file.url)" class="w-full hover:scale-150 h-full bg-cover rounded-md cursor-pointer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="mt-4">
          <div class="block text-purple-500">
            Type
          </div>
          <div class="block mt-2">
            <p class="text-lg" v-if="item.type === 0">
              Expense
            </p>
            <p class="text-lg" v-else-if="item.type === 1">
              Asset
            </p>
            <p class="text-lg" v-else-if="item.type === 2">
              Advanced payment
            </p>
          </div>
        </div>
        <div v-if="item.employeePayouts" class="mt-4">
          <div class="block text-purple-500">
            Employee payouts
          </div>
          <div class="block mt-2">          
            <div v-for="(employeePayout, key) in item.employeePayouts" :key="key" class="mt-4 block">
              <div class="p-4 bg-black-50 rounded-lg">
                <div class="block text-purple-500">
                    Employee
                  </div>
                  <div class="block mt-2">
                    {{ employeePayout.employeeName }}
                  </div>
                  <div class="block mt-2">
                    <p class="text-lg">
                      {{ formatDollar(employeePayout.amount) }}
                    </p>
                  </div>     
              </div>
              </div>
            </div>
        </div>
        <div class="mt-4">
          <div class="block text-purple-500">
            Expense Category
          </div>
          <div class="block mt-2">
            <p class="text-lg">
              {{ item.expenseCategoryName }}
            </p>
          </div>
        </div>
        <div class="mt-4">
          <div class="block text-purple-500">
            Expense
          </div>
          <div class="block mt-2">
            <p class="text-lg">
              {{ item.expenseHeadName }}
            </p>
          </div>
        </div>
        <div v-if="item.customFields.length > 0">
          <div v-for="(field, key) in item.customFields" :key="key" class="block mt-4">
            <div class="block text-purple-500">
              {{ field.name }}
            </div>
            <div class="block mt-2">
              <p class="text-lg">
                {{ field.value }}
              </p>
            </div>
          </div>
        </div>
        <div class="block mt-4">
          <div v-if="item.productImages.length > 0" class="block">
            <div class="block text-purple-500">
              Attachments
            </div>
            <div class="block mt-2">
              <div class="flex overflow-x-auto">
                <div v-for="(file, key) in item.productImages" :key="key">
                  <div class="w-56 h-36 bg-cover mr-5 rounded-md">
                    <img :src="file"
                         v-on:click="toggleModal(file)" class="w-full hover:scale-150 h-full bg-cover rounded-md cursor-pointer">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.productVideos.length > 0" class="block" v-bind:class="{'mt-8': item.productImages.length > 0}">
            <div class="block text-purple-500">
              Videos
            </div>
            <div class="block mt-2">
              <div class="flex overflow-x-auto">
                <div v-for="(file, key) in item.productVideos" :key="key">
                  <video class="w-56 rounded-md mr-5" controls>
                    <source :src="file" type="video/mp4">
                    <source :src="file" type="video/ogg">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal"/>
  </div>
</template>

<script>
import ImgViewer from '@/components/modals/receipt/ImageViewer';
export default {
  name: "ReceiptItems",
  components: {
    ImgViewer,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    receipt: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    imgUrl: null,
  }),
  methods: {
    toggleModal: function(url = '') {
      this.imgUrl = url;
      this.showModal = !this.showModal;
    },
    formatDollar: function(value) {
      if (this.receipt.currency === 0) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AED',
          minimumFractionDigits: 2
        }).format(value);
      } else if (this.receipt.currency === 1) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(value);
      } else if (this.receipt.currency === 2) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }).format(value);
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AED',
          minimumFractionDigits: 2
        }).format(value);
      }
    },
  }
}
</script>

<style scoped>

</style>
