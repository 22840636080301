<template>
  <div>
    <div class="hidden md:block">
      <nav class="w-full flex items-center justify-between" aria-label="Pagination">
        <div class="flex-1">
          <div class="text-sm flex text-gray-700">
            <span class="hidden md:block">
              Showing
            </span>
            <span class="font-medium pl-1 font-medium text-green-600 ">{{ pagination.show }}</span>
            <div v-if="Number(pagination.totalItems) > Number(pagination.to)" class="pl-1">
              to
              <span class="font-medium">{{ pagination.to }}</span>
              of
            </div>
            <div v-else class="pl-1">
              to
              <span class="font-medium">{{ pagination.totalItems }}</span>
              of
            </div>
            <span class="font-medium pl-1 pr-1">{{ pagination.totalItems }}</span>
            <span class="hidden md:block">
              results
            </span>
          </div>
        </div>
        <div>
          <nav v-if="pagination.pages && pagination.pages.length > 1" class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <div v-for="(item, key) in pagination.pages" :key="key">
              <span v-if="item === page" aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ page }} </span>
              <span v-else v-on:click="goToPage(item)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ item }} </span>
            </div>
          </nav>
        </div>
      </nav>
    </div>
    <div class="block md:hidden">
      <nav v-if="pagination.pages && pagination.pages.length > 1" class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <div v-for="(item, key) in pagination.pages" :key="key">
          <span v-if="item === page" aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ page }} </span>
          <span v-else v-on:click="goToPage(item)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ item }} </span>
        </div>
      </nav>
      <div class="text-sm flex py-2 px-2 text-gray-700">
        <span class="hidden md:block">
          Showing
        </span>
        <span class="font-medium pl-1 font-medium text-green-600 ">{{ pagination.show }}</span>
        <div v-if="Number(pagination.totalItems) > Number(pagination.to)" class="pl-1">
          to
          <span class="font-medium">{{ pagination.to }}</span>
          of
        </div>
        <div v-else class="pl-1">
          to
          <span class="font-medium">{{ pagination.totalItems }}</span>
          of
        </div>
        <span class="font-medium pl-1 pr-1">{{ pagination.totalItems }}</span>
        <span class="hidden md:block">
          results
        </span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: Object,
    page: Number,
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
  },
  computed: {

  },
  methods: {
    nextPage: function() {
      this.$emit('nextPage');
    },
    previousPage: function() {
      this.$emit('previousPage');
    },
    goToPage: function(page) {
      this.$emit('goToPage', page);
    }
  }
}
</script>

<style scoped>

</style>
