<template>
  <div class="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <div>
          <div class="sm:border-gray-200">
            <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Account completion</h1>
            <p class="mt-1 text-base text-gray-500">We are activating your account</p>
            <div v-if="loading" class="w-5 mx-auto">
              <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import AuthenticateService from "@/service/authenticate";

export default {
  name: "Activation.vue",
  data: () => ({
    token: null,
    loading: false,
  }),
  beforeMount: function() {
    this.token = this.$route.query.token;
  },
  mounted: function() {
    this.verifyAccount();
  },
  methods: {
    verifyAccount: async function() {
      this.loading = !this.loading;
      const service = new AuthenticateService();

      const dto = {
        token: this.token
      }
      service.verifyAccount(dto)
        .then(() => {
          this.$router.push({name: 'Login', query: {'status': 'success'}});
        }).catch((e) => {
          console.log(e.request);
          this.$notifications(
            'Something Went Wrong',
            'Error completing your account',
            '',
            1,
            true);
        }).finally(() => {
          this.loading = !this.loading;
        });
    }
  },
}
</script>

<style scoped>

</style>
