<template>
  <div id="#login" class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="h-12 mx-auto w-auto" src="@/assets/logo.png" alt="Accountant Chain">
        <h2 v-if="!emailSend" class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot your password?
        </h2>
        <h2 v-if="emailSend" class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Check your email
        </h2>
        <p v-if="emailSend">
          We've send you a link to reset your password.
        </p>
      </div>
      <div v-if="!emailSend" class="-space-y-px">
        <div class="rounded-md shadow-sm ">
          <div>
            <label for="email" class="sr-only">Email</label>
            <input id="email"
                   v-model="email"
                   name="email"
                   type="email"
                   required
                   class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                   placeholder="Email">
          </div>
        </div>
      </div>
      <div v-if="!emailSend">
        <button v-if="isValidEmail && !loading" v-on:click="forgotPassword" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Reset password
        </button>
        <button v-else-if="isValidEmail && loading" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          Reset password
        </button>
        <button v-else class="group cursor-not-allowed relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-300">
          Reset password
        </button>
      </div>
      <div>
        <div class="items-center mt-6">
          <div class="text-sm text-center">
            <router-link :to="{name: 'Homepage'}" class="font-medium text-blue-500 hover:text-blue-600">
              Back to homepage
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AuthenticateService from "@/service/authenticate";

export default Vue.extend({
  name: "ForgotPassword.vue",
  data: () => ({
    loading: false,
    email: '',
    isValidEmail: true,
    emailSend: false,
  }),
  methods: {
    forgotPassword: async function() {
      this.loading = true;
      const dto = {
        email: this.email.toLowerCase(),
      }

      const service = new AuthenticateService();
      await service.forgotPassword(dto)
        .then(() => {
          this.emailSend = true;
        }).catch((e) => {
          this.$notifications(
              'Internal Server Error',
              `Something went wrong logging in. Status code: 500`,
              '',
              1,
              true
          );
        }).finally(() => {
          this.loading = false;
        });
    }
  },
});
</script>
