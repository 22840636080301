<template>
  <div>
    <div class="credentials">
      <div class="header">
        <h2 class="text-3xl font-bold mt-2">
          Credentials
        </h2>
        <p class="w-2/3 py-3">
          The email address and password are required to access the data. We will also use this email address to keep you informed.
        </p>
      </div>
      <div class="body mt-8">
        <div class="w-2/3">
          <div class="block">
            <label :for="username" class="block text-gray-900 text-sm">Username <span class="text-red-500 pl-1">*</span></label>
            <input type="text" v-model="username" required class="bg-gray-100 mt-3 py-4 px-4 text-gray-800 w-full rounded-lg border-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Username">
            <div v-if="error.username !== ''" class="block my-2 px-3 py-2 rounded-md bg-red-100">
              <p class="text-red-500 text-sm">
                {{ error.username }}
              </p>
            </div>
            <div v-else-if="username === ''" class="block my-2 px-3 py-2 rounded-md bg-red-100">
              <p class="text-red-500 text-sm">
                Username can not be blank.
              </p>
            </div>
          </div>
          <div class="block mt-6">
            <label :for="email" class="block text-gray-900 text-sm">Email <span class="text-red-500 pl-1">*</span></label>
            <input type="email" v-model="email" required class="bg-gray-100 mt-3 py-4 px-4 text-gray-800 w-full rounded-lg border-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="John@doe.com">
            <div v-if="emailChanged" class="block my-2 px-3 py-2 rounded-md bg-blue-100">
              <p class="text-blue-500 text-sm">
                If you change your email, we'll send you an email on your new email address to confirm your email.
              </p>
            </div>
            <div v-else-if="email === ''" class="block my-2 px-3 py-2 rounded-md bg-red-100">
              <p class="text-red-500 text-sm">
                Email can not be blank.
              </p>
            </div>
          </div>
          <div class="block mt-6">
            <div>
              <label :for="password.current" class="block text-gray-900 text-sm">Current password</label>
              <div class="relative rounded-md shadow-sm">
                <input type="password" id="password" v-model="password.current" required class="bg-gray-100 mt-3 py-4 px-4 text-gray-800 w-full rounded-lg border-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Current password">
                <div class="absolute inset-y-0 top-2 right-0 pr-10 flex items-center cursor-pointer">
                  <svg v-if="!showPassword" v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="block mt-6">
            <div>
              <label :for="password.new" class="block text-gray-900 text-sm">New password</label>
              <div class="relative rounded-md shadow-sm">
                <input type="password" id="passwordNew" v-model="password.new" required class="bg-gray-100 mt-3 py-4 px-4 text-gray-800 w-full rounded-lg border-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="New password">
                <div class="absolute inset-y-0 top-2 right-0 pr-10 flex items-center cursor-pointer">
                  <svg v-if="!showNewPassword" v-on:click="toggleNewPasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else v-on:click="toggleNewPasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </div>
              </div>
            </div>
            <div v-if="!passwordIsValid && password.new !== ''" class="block my-2 px-3 py-2 rounded-md bg-red-100">
              <p class="text-red-500 text-sm">
                Your new password is too weak. Please match the requirements
              </p>
            </div>
            <div v-if="passwordIsValid && password.new !== ''" class="block my-2 px-3 py-2 rounded-md bg-green-100">
              <p class="text-green-500 text-sm">
                Your new password is strong
              </p>
            </div>
            <p class="text-gray-400 mt-3 text-sm">
              The password should be at least 8 characters long, include 1 lowercase letter, 1 capital letter, 1 number and 1 special character
            </p>
          </div>
          <div v-if="passwordIsValid && password.new !== '' && password.current === ''" class="block my-2 px-3 py-2 rounded-md bg-blue-100">
            <p class="text-blue-500 text-sm">
              Enter your current password to change your password
            </p>
          </div>
          <div v-else-if="password.new === password.current && password.new !== '' && password.current !== '' " class="block my-2 px-3 py-2 rounded-md bg-red-100">
            <p class="text-red-500 text-sm">
              Your new password is the same as the current password.
            </p>
          </div>
          <div class="flex mt-6">
            <button v-if="!loading && isValid && passwordIsValid" v-on:click="updateCredentials" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Save
            </button>
            <button v-else-if="loading && isValid && passwordIsValid" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Save
            </button>
            <button v-else class="inline-flex cursor-not-allowed items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 focus:outline-none">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from "@/service/account";

export default {
  name: "Account.vue",
  props: {
    profile: Object
  },
  data: () => ({
    showPassword: false,
    showNewPassword: false,
    password: {
      new: '',
      current: ''
    },
    username: '',
    email: '',
    error: {
      username: ''
    },
    emailChanged: false,
    isValid: false,
    passwordIsValid: false,
    loading: false,
  }),
  beforeMount: function() {
    this.username = this.profile.username;
    this.email = this.profile.email;

    if (this.username !== '' && this.email !== '' && this.password.new === '' && this.password.current === '') {
      this.isValid = true;
      this.passwordIsValid = true;
    }
  },
  methods: {
    updateCredentials: async function() {
      this.loading = !this.loading;
      let bool = false;
      if (this.password.new === '' && this.password.current === '' && this.username !== '' && this.email !== '') {
        // Only post username and email
        const dto = {
          userName: this.username,
          email: this.email,
          password: null,
          confirmPassword: null,
          currentPassword: null,
        }
        await this.updateProfile(dto, bool);

      } else if (this.password.new !== '' && this.password.current !== '' && this.username !== '' && this.email !== '') {
        // Post username, email and passwords
        const dto = {
          userName: this.username,
          email: this.email,
          password: this.password.new,
          confirmPassword: this.password.new,
          currentPassword: this.password.current
        }
        bool = true
        await this.updateProfile(dto, bool);
      }
    },
    updateProfile: async function(dto, bool) {
      const service = new AccountService();

      await service.updateProfile(dto)
        .then((response) => {
          this.password.new = null;
          this.password.current = null;

          if (response.status === 200)
            if (this.email !== this.profile.email) {
              this.$notifications(
                  'Please Activate Your New Email Address',
                  'You\'re profile is successfully updated.',
                  '',
                  0,
                  true
              )
            } else {
              if (bool) {
                this.$notifications(
                    'Your Password Is Updated',
                    'We signed you out. Please sign in again.',
                    '',
                    0,
                    true
                );
                this.$router.push({name: 'Login'});
              } else {
                this.$notifications(
                    'Profile Updated',
                    'You\'re profile is successfully updated.',
                    '',
                    0,
                    true
                );
              }
            }

          this.loading = !this.loading;
          this.$root.$emit('SetUsername', this.username);
          this.$root.$emit('SetPageHeaderUsername', this.username);
          this.$emit('getUserProfile');
        }).catch((e) => {
            this.loading = !this.loading;
            const response = JSON.parse(e.request.response);
            this.$notifications(
                `There was an error updating your profile.`,
                `${response.message}`,
                '',
                1,
                true
            );
        });
    },
    togglePasswordType: function() {
      this.showPassword = !this.showPassword;
      const type = document.getElementById('password').getAttribute('type');
      if (type === 'password')
        document.getElementById('password').setAttribute('type', 'text');
      else
        document.getElementById('password').setAttribute('type', 'password');
    },
    toggleNewPasswordType: function() {
      this.showNewPassword = !this.showNewPassword;
      const type = document.getElementById('passwordNew').getAttribute('type');
      if (type === 'password')
        document.getElementById('passwordNew').setAttribute('type', 'text');
      else
        document.getElementById('passwordNew').setAttribute('type', 'password');
    }
  },
  watch: {
    email: {
      handler: function() {
        if (this.username === '' || this.email === '') {
          this.isValid = false;
        } else if (this.password.new === '' && this.password.current === '' && this.username !== '' || this.email !== '') {
          this.isValid = true;
        }

        this.emailChanged = this.email !== this.profile.email && this.email !== '';
      },
      deep: true
    },
    password: {
      handler: function() {
        let validNewPassword = false;
        if (this.password.current !== '' && this.password.new === '') {
          this.passwordIsValid = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*{}();',.<>?~`+_-])[\w!@#$%^&*{}();',.<>?~`+_-]{8,}$/.test(this.password.current);
        } else if (this.password.new !== '' && this.password.current !== '') {
          /**
           * Password validation RegEx for JavaScript
           *
           * Passwords must be
           * - At least 8 characters long, max length anything
           * - Include at least 1 lowercase letter
           * - 1 capital letter
           * - 1 number
           * - 1 special character => !@#$%^&*{}();',.<>?~`+_-
           *
           *
           */
          const resultNew = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*{}();',.<>?~`+_-])[\w!@#$%^&*{}();',.<>?~`+_-]{8,}$/.test(this.password.new);
          if (resultNew) {
            this.passwordIsValid = true;
            validNewPassword = this.password.current !== this.password.new;
          } else {
            this.passwordIsValid = false;
          }

          if (this.password.current && validNewPassword)
            this.passwordIsValid = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*{};',.<>?~`+_-])[\w!@#$%^&*{};',.<>?~`+_-]{8,}$/.test(this.password.current);
        } else {
          this.passwordIsValid = false;
        }

        if (this.password.new !== '' && this.password.current === '')
          this.passwordIsValid = false;
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
