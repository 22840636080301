<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div  class="fixed inset-0 overflow-y-auto z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
          <button v-on:click="toggleModal" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="bg-white capitalize overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h3 v-if="!isEdit" class="text-lg leading-6 font-medium text-gray-900">
              Create Business
            </h3>
            <h3 v-else class="text-lg leading-6 font-medium text-gray-900">
              Edit Business
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Business information
            </p>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Name of business
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.name"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Name">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  About
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <textarea rows="10" v-model="business.about" placeholder="About" class="shadow-sm focus:border-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"></textarea>
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company address
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyAddress"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Address">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company Zipcode
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyZipcode"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Zipcode">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company City
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyCity"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company City">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company Province
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyProvince"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Province">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company Country
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyCountry"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Country">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company email
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyEmail"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Email">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Company phone
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.companyPhoneNr"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Company Phone">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Legal name
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.legalName"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Legal Name">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Business ID Number
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.businessIdNo"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Business ID No">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Tax registration Number
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.taxRegistrationNumber"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Tax Registration Nr">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Tax form
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.taxForm"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Tax Form">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Industry
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <select required v-on:change="setIndustry($event)" v-model="business.industry" class="w-full block focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm border-gray-300 rounded-md">
                      <option selected disabled :value="null">Select Industry</option>
                      <option v-for="(industry, key) in industries" :key="key" :value="industry.id">{{industry.name}}</option>
                    </select>
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Latitude Coordinate
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.latitude"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Latitude Coordinate">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Longitude Coordinate
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="business.longitude"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Longitude Coordinate">
                  </div>
                </dd>
              </div>
              <Files label="Company images" divId="companyImg" :selectedFiles="business.selectedImages" uploadTitle="Upload Photos" :uploadType="6" @setFiles="setFiles" @deleteFile="deleteFile"/>
              <Files label="Company videos" divId="companyVid" :selectedFiles="business.selectedVideos" uploadTitle="Upload Videos" :uploadType="7" @setFiles="setFiles" @deleteFile="deleteFile"/>
              <Files label="Business License" divId="companyLicense" :selectedFiles="business.selectedLicense" uploadTitle="Upload Document or Photo" :uploadType="8" @setFiles="setFiles" @deleteFile="deleteFile"/>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Website url
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[0].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Website url">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Twitter
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[1].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Twitter url">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Facebook
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[2].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Facebook url">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Telegram
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[3].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Telegram url">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Discord
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[4].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Discord url">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Other social media
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input type="text"
                           v-model="socials[5].url"
                           class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-500 rounded-md"
                           placeholder="Url">
                  </div>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Public
                  <div class="block">
                    <span class="text-sm text-gray-500">Receipts are public</span>
                  </div>
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input v-model="isPublic" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-500 rounded">
                  </div>
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Private
                  <div class="block">
                    <span class="text-sm text-gray-500">Receipts won't be public</span>
                  </div>
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>
                    <input v-model="isPrivate" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-500 rounded">
                  </div>
                </dd>
              </div>
              <div v-if="!isEdit" class="sm:py-5 text-center sm:px-6">
                <button v-if="!loading && business.name !== ''" v-on:click="createBusiness(false)" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Create Business
                </button>
                <button v-else-if="!loading && business.name === ''" class="inline-flex cursor-not-allowed items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Create Business
                </button>
                <button v-else-if="loading" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Create Business
                </button>
              </div>
              <div v-else class="sm:py-5 text-center sm:px-6">
                <button v-if="!loading" v-on:click="editBusiness(false)" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  Save Business
                </button>
                <button v-else-if="loading" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Save Business
                </button>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessService from "@/service/business";
import Files from "@/components/form/receipt/Files";

export default {
  name: "Form.business",
  components: {
    Files
  },
  props: {
    dto: {
      type: Object,
      required: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data:() => ({
    counterTick: 0,
    isPublic: true,
    isPrivate: false,
    business: {
      name: '',
      about: null,
      legalName: null,
      businessIdNo: null,
      taxRegistrationNumber: null,
      taxForm: null,
      industry: null,
      companyEmail: null,
      companyPhoneNr: null,
      companyAddress: null,
      companyCity: null,
      companyZipcode: null,
      companyProvince: null,
      companyCountry: null,
      businessLicense: null,
      images: [],
      videos: [],
      isPrivate: false,
      usesFourEyesPrinciple: false,
      longitude: null,
      latitude: null,
      socials: [],
    },
    socials: [
      {
        url: null,
        type: 0 // website
      },
      {
        url: null,
        type: 1 // twitter
      },
      {
        url: null,
        type: 2 // facebook
      },
      {
        url: null,
        type: 3 // telegram
      },
      {
        url: null,
        type: 4 // discord
      },
      {
        url: null,
        type: 5 // other
      },
    ],
    loading: false,
    industries: [
      {
        id: 1,
        name: "Agriculture"
      },
      {
        id: 2,
        name: "Automotive"
      },
      {
        id: 3,
        name: "Banking"
      },
      {
        id: 4,
        name: "Chemical",
      },
      {
        id: 5,
        name: "Construction"
      },
      {
        id: 6,
        name: "Consulting"
      },
      {
        id: 7,
        name: "Education"
      },
      {
        id: 8,
        name: "Electronics"
      },
      {
        id: 9,
        name: "Energy"
      },
      {
        id: 10,
        name: "Entertainment"
      },
      {
        id: 11,
        name: "Financial Services"
      },
      {
        id: 12,
        name: "Food & Beverage"
      },
      {
        id: 13,
        name: "Government"
      },
      {
        id: 14,
        name: "Healthcare"
      },
      {
        id: 15,
        name: "Insurance"
      },
      {
        id: 16,
        name: "Manufacturing"
      },
      {
        id: 17,
        name: "Media"
      },
      {
        id: 18,
        name: "Non-Profit"
      },
      {
        id: 19,
        name: "Pharmaceutical"
      },
      {
        id: 20,
        name: "Real Estate"
      },
      {
        id: 21,
        name: "Retail"
      },
      {
        id: 22,
        name: "Technology"
      },
      {
        id: 23,
        name: "Telecommunications"
      },
      {
        id: 24,
        name: "Transportation"
      },
      {
        id: 25,
        name: "Travel"
      },
      {
        id: 26,
        name: "Other"
      }
    ],
  }),
  created: async function() {
    await this.created();
  },
  methods: {
    created: async function() {
      if (this.isEdit) {
        this.business = {
          id: this.dto.id,
          name: this.dto.name,
          about: this.dto.about,
          legalName: this.dto.legalName,
          businessIdNo: this.dto.businessIdNo,
          taxRegistrationNumber: this.dto.taxRegistrationNumber,
          taxForm: this.dto.taxForm,
          industry: this.dto.industry,
          companyEmail: this.dto.companyEmail,
          companyPhoneNr: this.dto.companyPhoneNr,
          companyAddress: this.dto.companyAddress,
          companyCity: this.dto.companyCity,
          companyZipcode: this.dto.companyZipcode,
          companyCountry: this.dto.companyCountry,
          companyProvince: this.dto.companyProvince,
          businessLicense: null,
          selectedLicense: [this.dto.businessLicense],
          images: [],
          selectedImages: this.dto.images,
          videos: [],
          selectedVideos: this.dto.videos,
          isPrivate: this.dto.isPrivate,
          usesFourEyesPrinciple: this.dto.usesFourEyesPrinciple,
          longitude: this.dto.longitude,
          latitude: this.dto.latitude,
          socials: this.dto.socials,
        }

        if (this.dto.socials && this.dto.socials.length > 0) {
          this.socials[0] = this.dto.socials[0];
          if (this.dto.socials[1]) {
            this.socials[1] = this.dto.socials[1];
          }
          if (this.dto.socials[2]) {
            this.socials[2] = this.dto.socials[2];
          }
          if (this.dto.socials[3]) {
            this.socials[3] = this.dto.socials[3];
          }
          if (this.dto.socials[4]) {
            this.socials[4] = this.dto.socials[4];
          }
          if (this.dto.socials[5]) {
            this.socials[5] = this.dto.socials[5];
          }
        }

        if (this.business.isPrivate) {
          this.isPrivate = true;
        }
      }
    },
    createBusiness: async function(bool) {
      this.loading = !this.loading;
      const service = new BusinessService();
      const tempSocials = this.socials;
      this.business.socials = [];
      this.business.socials = tempSocials;
      await service.createBusiness(this.business)
        .then(() => {
          this.business.socials = []; // reset to empty array
          this.$root.$emit('BusinessCreated');
          this.setSocials(tempSocials);
          if (!bool) {
            this.$notifications(
                `Company Created`,
                `The company ${this.business.name} is successfully created`,
                '',
                0,
                true
            );

            this.$store.state.firstSignIn = false;
            this.$emit('toggleModal');
          }
          this.loading = !this.loading;
        }).catch((e) => {
          this.loading = !this.loading;
          if (e.request && e.request.response) {
            const error = JSON.parse(e.request.response);
            this.$notifications(
                'Something Went Wrong Creating a Business.',
                `Error message: ${error.message}`,
                '',
                1,
                true
            );
          }
        });
    },
    editBusiness: async function(bool) {
      this.loading = !this.loading;
      const service = new BusinessService();
      const tempSocials = this.socials;
      this.business.socials = [];
      this.business.socials = tempSocials;
      await service.editBusiness(this.business)
        .then(() => {
          this.business.socials = []; // reset to empty array
          this.setSocials(tempSocials);
          this.$root.$emit('BusinessCreated');
          this.$emit('fetchData');
          if (!bool) {
            this.$notifications(
                `Company Edited`,
                `The company ${this.business.name} is successfully edited`,
                '',
                0,
                true
            );
            this.$emit('toggleModal');
          }
          this.loading = !this.loading;
        }).catch((e) => {
          this.loading = !this.loading;
          if (e.request && e.request.response) {
            const error = JSON.parse(e.request.response);
            this.$notifications(
              'Something Went Wrong Editing The Business.',
              `Error message: ${error.message}`,
              '',
              1,
              true
            );
          }
        });
    },
    setFiles: function(files, type, index) {
      if (type === 6) {
        this.business.images = files;
      } else if (type===7) {
        this.business.videos = files;
      } else if (type === 8) {
        this.business.businessLicense = files[0];
      }

      // this.focusInput();
    },
    deleteFile(key, type, index) {
      if (this.isEdit) {
        if (type === 6) {
          this.business.selectedImages.splice(key, 1);
        } else if (type === 7) {
          const videos = this.business.selectedVideos;
          this.business.selectedVideos = [];
          videos.forEach((video, i) => {
            if (i !== key) {
              this.business.selectedVideos.push(video);
            }
          });
        } else if (type === 8) {
          this.business.selectedLicense = null;
        }
      } else {
        if (type === 6) {
          this.business.images.splice(key, 1);
        } else if (type === 7) {
          this.business.videos.splice(key, 1);
        } else if (type === 8) {
          this.business.businessLicense = null;
        }
      }
      // this.focusInput();
    },
    focusInput: async function() {
      if (this.isEdit) {
        await this.editBusiness(true);
      } else {
        await this.createBusiness(true);
      }
    },
    setIndustry: function(event) {
      this.business.industry = event.target.value;
    },
    setSocials: function(array) {
      if (array.length > 0) {
        this.socials = array;
      } else {
        this.socials = [
          {
            url: null,
            type: 0 // website
          },
          {
            url: null,
            type: 1 // twitter
          },
          {
            url: null,
            type: 2 // facebook
          },
          {
            url: null,
            type: 3 // telegram
          },
          {
            url: null,
            type: 4 // discord
          },
          {
            url: null,
            type: 5 // other
          },
        ]
      }
    },
    toggleModal: function() {
      this.$emit('toggleModal');
    },
  },
  watch: {
    isPublic: {
      handler: function() {
        if (this.isPublic) {
          this.isPrivate = false;
          this.business.isPrivate = false;
        }
      },
      deep: true
    },
    isPrivate: {
      handler: function() {
        if (this.isPrivate) {
          this.isPublic = false;
          this.business.isPrivate = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
