<template>
<div>
  <div :class="{
    'mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4': stats.companies !== null,
    'mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4 ': stats.companies === null,
  }">
    <div v-if="stats.companies !== null" class="bg-white overflow-hidden shadow-lg rounded-lg">
      <div class="p-3">
        <div class="flex items-center">
          <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-100 text-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </div>
          <div class="ml-5 w-0 flex-1">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Total Businesses Registered
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{ stats.companies }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white overflow-hidden shadow-lg rounded-lg">
      <div class="p-3">
        <div class="flex items-center">
          <div  class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div :class="{'ml-5': stats.companies === null}" class="w-0 flex-1 ml-5">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Total Funds Spent
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{ stats.funds}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div :class="{
          'bg-white overflow-hidden shadow-lg rounded-lg hover:bg-blue-100 cursor-pointer': filterType === null || filterType !== 0,
          'bg-blue-100 overflow-hidden shadow-lg rounded-lg hover:bg-blue-200 cursor-pointer': filterType === 0,
          }">
      <a href="#receipt" v-on:click="filterReceipt(0)">
        <div class="p-3">
          <div class="flex items-center">
            <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-yellow-100 text-yellow-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Total Expenses
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ stats.expenses }} &nbsp;
                    {{ stats.expenseAmount}}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div :class="{
          'bg-white overflow-hidden shadow-lg rounded-lg hover:bg-blue-100 cursor-pointer': filterType === null || filterType !== 2,
          'bg-blue-100 overflow-hidden shadow-lg rounded-lg hover:bg-blue-200 cursor-pointer': filterType === 2,
          }">
      <a href="#receipt" v-on:click="filterReceipt(2)">
        <div class="p-3">
          <div class="flex items-center">
            <div  class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div :class="{'ml-5': stats.companies === null}" class="w-0 flex-1 ml-5">
              <dl>
                <dt class="text-sm font-medium text-gray-500">
                  Advance Payment to Suppliers
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ stats.advancedPaymentsCount }} &nbsp;
                    {{ stats.advancedPayments}}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div :class="{
          'bg-white overflow-hidden shadow-lg rounded-lg hover:bg-blue-100 cursor-pointer': filterType === null || filterType !== 3,
          'bg-blue-100 overflow-hidden shadow-lg rounded-lg hover:bg-blue-200 cursor-pointer': filterType === 3,
          }">
      <a href="#receipt" v-on:click="filterReceipt(3)">
        <div class="p-3">
          <div class="flex items-center">
            <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-gray-200 text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Total Receipts
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ stats.receipts }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div :class="{
          'col-span-1 bg-white overflow-hidden shadow-lg rounded-lg hover:bg-blue-100 cursor-pointer': filterType === null || filterType !== 1,
          'col-span-1 bg-blue-100 overflow-hidden shadow-lg rounded-lg hover:bg-blue-200 cursor-pointer': filterType === 1,
          }">
      <a href="#receipt" v-on:click="filterReceipt(1)">
        <div class="p-3">
          <div class="flex items-center">
            <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl  bg-red-100 text-red-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Total Assets
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ stats.assets }} &nbsp;
                    {{ stats.assetAmount}}

                    <span v-bind:class = "(stats.assetsPercentageDifference > 0)?'text-green':'text-red'"  v-if="stats.assetsPercentageDifference">({{stats.assetsPercentageDifference > 0 ? '+' : ''}}{{stats.assetsPercentageDifference.toFixed(2) + '%'}})</span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="bg-white overflow-hidden shadow-lg rounded-lg">
      <div class="p-3">
        <div class="flex items-center">
          <div  class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-gray-200 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div :class="{'ml-5': stats.companies === null}" class="w-0 flex-1 ml-5">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Monthly avg. for this year
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{ stats.averageCostsMonth}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div :class="{
          'bg-white overflow-hidden shadow-lg rounded-lg lg:col-span-1 sm:col-span-1': stats.companies !== null,
          'bg-white overflow-hidden shadow-lg rounded-lg': stats.companies === null,
        }
    ">
      <div class="p-3">
        <div class="flex items-center">
          <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-gray-200 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div :class="{'ml-5': stats.companies === null}" class="w-0 flex-1 ml-5">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Total costs this month
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{ stats.totalCostsMonth}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.$route.matched.some(({ name }) => name === 'BusinessPublicShow') || this.$route.matched.some(({ name }) => name === 'BusinessShow')" :class="{
          'bg-white overflow-hidden shadow-lg rounded-lg lg:col-span-1 sm:col-span-1': stats.companies !== null,
          'bg-white overflow-hidden shadow-lg rounded-lg lg:col-span-1 sm:col-span-1': stats.companies === null,
        }
    ">
      <div class="p-3">
        <div class="flex items-center">
          <div class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-gray-200 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div :class="{'ml-5': stats.companies === null}" class="w-0 flex-1 ml-5">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Total costs previous month
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{ stats.totalCostsPreviousMonth}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import StatsService from "@/service/stats";
import store from '@/store';
import FormatHelper from "@/helpers/format";
import * as Url from "url";

export default {
  name: "Stats",
  props: {
    companyName: String,
    company: Object,
  },
  data: () => ({
    authenticated: store.getters.authenticated,
    id: null,
    filterType: null,
    stats: {
      companies: 0,
      receipts: 0,
      funds: 0,
      expenses: 0,
      expenseAmount: 0,
      assets: 0,
      assetAmount: 0,
      averageCostsMonth: 0,
      totalCostsMonth: 0,
      totalCostsPreviousMonth: 0,
      advancedPayments: 0,
      advancedPaymentsCount: 0,
      assetsPercentageDifference: null,
    },
    accessToken: '',
    copied: false,
  }),
  beforeMount: async function() {
    if (this.$route.matched.some(({ name }) => name === 'Dashboard')) {
      await this.getGlobalStats();
    } else if (this.$route.matched.some(({ name }) => name === 'Homepage')) {
      await this.getGlobalStats();
    } else if (this.$route.matched.some(({ name }) => name === 'BusinessPublicShow')) {
      await this.getCompanyStats();
    } else if (this.$route.matched.some(({ name }) => name === 'BusinessShow')) {
      await this.getCompanyStats();
    }

    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
    }

    // format to dollar
    await this.formatFunds(1);

    this.$root.$on('setCurrency', async (value) => {
      if (this.$route.matched.some(({ name }) => name === 'Dashboard')) {
        await this.getGlobalStats();
      } else if (this.$route.matched.some(({ name }) => name === 'Homepage')) {
        await this.getGlobalStats();
      } else if (this.$route.matched.some(({ name }) => name === 'BusinessPublicShow')) {
        await this.getCompanyStats();
      } else if (this.$route.matched.some(({ name }) => name === 'BusinessShow')) {
        await this.getCompanyStats();
      }
      if (value === 'AED') {
        await this.formatFunds(3);
      } else if (value === 'EUR') {
        await this.formatFunds(2);
      } else if (value === 'USD') {
        await this.formatFunds(1);
      }
    });
  },
  methods: {
    getAccountStats: async function() {
      const service = new StatsService();
      await service.getAccountStats()
        .then((response) => {
          this.stats = {
            companies: response.data.totalCompanies,
            receipts: response.data.totalReceipts,
            funds: response.data.totalFunds,
            expenses: response.data.totalExpenses,
            expenseAmount: response.data.totalExpensesAmount,
            assets: response.data.totalAssets,
            assetAmount: response.data.totalAssetsAmount,
            averageCostsMonth: response.data.averageCostsMonth,
            totalCostsMonth: response.data.totalCostsMonth,
            advancedPayments: response.data.advancedPayments,
            totalCostsPreviousMonth: response.data.totalCostsPreviousMonth,
            advancedPaymentsCount: response.data.totalAdvancedPayments,
            assetsPercentageDifference: response.data.assetsPercentageDifference
          }
        }).catch((e) => {
          console.log(e.request)
        });
    },
    getGlobalStats: async function() {
      const service = new StatsService();
      await service.getGlobalStats()
        .then((response) => {
          this.stats = {
            companies: response.data.totalCompanies,
            receipts: response.data.totalReceipts,
            funds: response.data.totalFunds,
            expenses: response.data.totalExpenses,
            expenseAmount: response.data.totalExpensesAmount,
            assets: response.data.totalAssets,
            assetAmount: response.data.totalAssetsAmount,
            averageCostsMonth: response.data.averageCostsMonth,
            totalCostsMonth: response.data.totalCostsMonth,
            advancedPayments: response.data.advancedPayments,
            totalCostsPreviousMonth: response.data.totalCostsPreviousMonth,
            advancedPaymentsCount: response.data.totalAdvancedPayments,
            assetsPercentageDifference: response.data.assetsPercentageDifference
          }
        }).catch((e) => {
          console.log(e.request)
        });
    },
    getCompanyStats: async function() {
      const service = new StatsService();
      await service.getCompanyStats(this.$route.params.id)
        .then((response) => {
          console.log(response.data)

          this.stats = {
            companies: null,
            receipts: response.data.totalReceipts,
            funds: response.data.totalFunds,
            expenses: response.data.totalExpenses,
            expenseAmount: response.data.totalExpensesAmount,
            assets: response.data.totalAssets,
            assetAmount: response.data.totalAssetsAmount,
            averageCostsMonth: response.data.averageCostsMonth,
            totalCostsMonth: response.data.totalCostsMonth,
            advancedPaymentsCount: response.data.totalAdvancedPayments,
            totalCostsPreviousMonth: response.data.totalCostsPreviousMonth,
            advancedPayments: response.data.advancedPayments,
            assetsPercentageDifference: response.data.assetsPercentageDifference
          }
        }).catch((e) => {
          console.log(e.request)
        });
    },
    filterReceipt: function(type) {
      if (type === this.filterType) {
        this.filterType = null;
        type = null;
      } else
        this.filterType = type;


      this.$root.$emit('FilterReceipts',type);
    },
    formatFunds: async function(type) {
        const helper = new FormatHelper();
        if (type === 3) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 3);
          this.stats.expenseAmount = await helper.formatFromDollar((this.stats.expenseAmount - this.stats.advancedPayments), 3);
          this.stats.assetAmount = await helper.formatFromDollar(this.stats.assetAmount, 3);
          this.stats.averageCostsMonth = await helper.formatFromDollar(this.stats.averageCostsMonth, 3);
          this.stats.totalCostsMonth = await helper.formatFromDollar(this.stats.totalCostsMonth, 3);
          this.stats.advancedPayments = await helper.formatFromDollar(this.stats.advancedPayments, 3);
          this.stats.totalCostsPreviousMonth = await helper.formatFromDollar(this.stats.totalCostsPreviousMonth, 3);
        } else if (type === 2) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 2);
          this.stats.expenseAmount = await helper.formatFromDollar((this.stats.expenseAmount - this.stats.advancedPayments), 2);
          this.stats.assetAmount = await helper.formatFromDollar(this.stats.assetAmount, 2);
          this.stats.averageCostsMonth = await helper.formatFromDollar(this.stats.averageCostsMonth, 2);
          this.stats.totalCostsMonth = await helper.formatFromDollar(this.stats.totalCostsMonth, 2);
          this.stats.advancedPayments = await helper.formatFromDollar(this.stats.advancedPayments, 2);
          this.stats.totalCostsPreviousMonth = await helper.formatFromDollar(this.stats.totalCostsPreviousMonth, 2);
        } else if (type === 1) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 1);
          this.stats.expenseAmount = await helper.formatFromDollar((this.stats.expenseAmount - this.stats.advancedPayments), 1);
          this.stats.assetAmount = await helper.formatFromDollar(this.stats.assetAmount, 1);
          this.stats.averageCostsMonth = await helper.formatFromDollar(this.stats.averageCostsMonth, 1);
          this.stats.totalCostsMonth = await helper.formatFromDollar(this.stats.totalCostsMonth, 1);
          this.stats.advancedPayments = await helper.formatFromDollar(this.stats.advancedPayments, 1);
          this.stats.totalCostsPreviousMonth = await helper.formatFromDollar(this.stats.totalCostsPreviousMonth, 1);
        }
    },
    formatDollar: function(value) {
      let formatter;
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      return formatter.format(value);
    },
  }
}
</script>

<style scoped>

</style>
