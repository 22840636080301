<template>
  <div>
    <Search v-if="!authenticated" />
       <div class="max-w-screen-2xl mx-auto px-4 mt-2 relative">
      <Breadcrumb :navigation="navigation" />
    </div>

    <div class="max-w-screen-2xl mx-auto px-4 mt-2 mb-2 relative">
      <SkeletonCards v-if="!loaded" />
      <Stats :company="company" :companyName="company.name" v-if="loaded" />
    </div>

    <div v-show="loaded">
      <div id="receipt" class="max-w-screen-2xl mx-auto px-4 py-1 relative">
        <div class="block py-1 rounded-lg">
          <div class="grid grid-cols-12 gap-3" >
            <div class="col-span-12  md:col-span-6  lg:col-span-4  boxDataSect">
              <h3 class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Details</h3>
              <div class="boxDBo">
                <h3 class="nameComp font-mono">
                  <span class="truncate">
                    {{ company.name }}
                  </span>
                  <br>
                  <span v-if="authenticated && (company.isOwner || company.canEdit)"
                    v-on:click="showEditBusinessModal = !showEditBusinessModal"
                    class="text-blue-500 text-sm cursor-pointer font-medium">
                    Edit your company
                  </span>
                </h3>
                <p v-if="company.companyAddress && company.companyAddress !== ''">
                    <span>{{ company.companyAddress }}</span>
                </p>
                <p v-if="(company.companyZipcode && company.companyZipcode !== '') 
                        || (company.companyCity && company.companyCity !== '')">
                    <span v-if="company.companyZipcode && company.companyZipcode !== ''">{{ company.companyZipcode }} &nbsp;</span>
                    <span v-if="company.companyCity && company.companyCity !== ''">{{ company.companyCity }} &nbsp;</span>                
                </p>
                <p v-if="company.companyCountry && company.companyCountry !== ''">
                    <span>{{ company.companyCountry }} &nbsp;</span>
                </p>
                <p v-if="company.companyPhoneNr && company.companyPhoneNr !== ''">
                  <span class="font-bold">Phone number</span> {{ company.companyPhoneNr }}
                </p>
                <p v-if="company.businessIdNo && company.businessIdNo !== ''">
                  <span class="font-bold">Business Id No:</span> {{ company.businessIdNo }}
                </p>
                <p v-if="company.taxRegistrationNumber && company.taxRegistrationNumber !== ''">
                  <span class="font-bold">Tax Registration Nr: </span> {{ company.taxRegistrationNumber }}
                </p>
                <p v-if="company.taxForm && company.taxForm !== ''">
                  <span class="font-bold">Tax Form</span> {{ company.taxForm }}
                </p>
                <p v-if="company.industry && company.industry !== ''">
                  <span class="font-bold">Industry</span> {{ company.industry }}
                </p>

                <div v-if="company.socials"  class="grid grid-cols-12 mt-2">
                  <div v-for="(social, key) in company.socials" :key="key" class="col-span-6">
                    <span class="">
                      <a :href="formatUrl(social.url)" target="_blank" class="text-blue-500 hover:text-blue-600">
                        {{social.url}}
                      </a>
                    </span>
                  </div>
                </div>

                <p class="pt-1" v-if="company.companyEmail && company.companyEmail !== ''">
                  <a :href="`mailto:${company.companyEmail}`" class="text-blue-500 group flex">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500 group-hover:text-blue-600"
                      fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg> -->
                    <span class="text-blue-500 group-hover:text-blue-600">email</span>
                  </a>
                </p>

                <a v-if="company.businessLicense" :href="company.businessLicense" target="_blank"
                  class="text-blue-500 pt-1">
                  Open Business License
                </a>
              </div>
            </div>
            <div v-if="company.about" class="col-span-12 md:col-span-12 lg:col-span-4 boxDataSect">
              <div v-if="company.about" class="text-left">
                <h3 class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">About</h3>
                <div class="boxDBo">

                  <p v-if="!showMore">
                    {{ truncateString(company.about, 550) }}
                  </p>
                  <p v-if="showMore">
                    <textarea v-model="company.about" readonly rows="10"
                      class="p-0 resize-none border-0 w-full focus:border-white"></textarea>
                  </p>
                </div>
              </div>
              <div v-if="company.about && company.about.length >= 550" class="block md:hidden text-center">
                <span v-on:click="showMore = !showMore" class="text-blue-500 text-sm cursor-pointer font-medium">
                  <span v-if="!showMore">Read More</span>
                  <span v-else>Show Less</span>
                </span>
              </div>
            </div>

            <div class="col-span-12 md:col-span-6  lg:col-span-4  boxDataSect" v-show="company.longitude && company.latitude" >
              <div class="col-span-9 md:col-span-1">
                <div>
                  <h3 class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Location</h3>
                  <div class="boxDBo">
                    <div id="map" class="h-64 z-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-3" v-if="company.images && company.images.length > 0">
            <div class="col-span-12 boxDataSect">
              <h3 class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Photo</h3>
              <div class="boxDBo">
                <div class="grid grid-cols-12 gap-3">
                  <div class="col-span-6  md:col-span-4  lg:col-span-2 text-center" v-for="(file, key) in company.images" :key="key">
                    <div v-if="key <= 5" class="rounded-md" >
                      <img :src="file" v-on:click="toggleModal(file)"
                        class="hover:scale-150 bg-cover rounded-md cursor-pointer picStyle">
                    </div>
                    <div v-else-if="showMore" class="rounded-md">
                      <img :src="file" v-on:click="toggleModal(file)"
                        class="hover:scale-150 bg-cover rounded-md cursor-pointer picStyle">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="company.images && company.images.length > 1" class="md:hidden text-center mt-2">
              <span v-on:click="showMore = !showMore" class="text-blue-500 text-sm cursor-pointer font-medium">
                <span v-if="!showMore">Show More Images</span>
                <span v-else>Show Less</span>
              </span>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-3" v-if="company.videos && company.videos.length > 1">
            <div class="col-span-12 boxDataSect">
              <h3 class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Video</h3>
              <div class="boxDBo">
                <div class="grid grid-cols-12 gap-3">
                  <div class="col-span-12 md:col-span-6 lg:col-span-2 " v-for="(file, key) in company.videos" :key="key">
                    <div v-if="key <= 5" class="cursor-pointer relative z-10">
                      <video class="rounded-md vidStyle" :id="`video-${key}`" controls poster="@/assets/logo.png">
                        <source :src="file" type="video/mp4">
                        <source :src="file" type="video/ogg">
                        Your browser does not support the video tag.
                      </video>
                      <button class="text-center w-full mt-1 p-2 rounded-md border border-gray-400"
                        v-on:click="openLargeScreen(key)">Play In Full Screen</button>
                    </div>
                    <div v-else-if="showMore" class="cursor-pointer relative z-10">
                      <video class="rounded-md vidStyle" :class="{'mt-2': key > 0}" :id="`video-${key}`" controls poster="@/assets/logo.png">
                        <source :src="file" type="video/mp4">
                        <source :src="file" type="video/ogg">
                        Your browser does not support the video tag.
                      </video>
                      <button class="text-center w-full mt-1 p-2 rounded-md border border-gray-400"
                        v-on:click="openLargeScreen(key)">Play In Full Screen</button>
                    </div>
                  </div>

                  <div v-if="company.videos && company.videos.length > 1" class="md:hidden col-span-12 text-center mt-2">
                    <span v-on:click="showMore = !showMore" class="text-blue-500 text-sm cursor-pointer block text-center font-medium">
                      <span v-if="!showMore">Show More Videos</span>
                      <span v-else>Show Less</span>
                    </span>
                  </div>
                </div>
                <div
                  v-if="company.about && company.about.length >= 550 || (((company.longitude && company.latitude) || ((company.images && company.images.length > 1) || (company.videos && company.videos.length > 1))))"
                  class="hidden md:block text-center mt-2">
                  <span v-on:click="showMore = !showMore" class="text-blue-500 text-sm cursor-pointer font-medium">
                    <span v-if="!showMore">Show More</span>
                    <span v-else>Show Less</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-screen-2xl mx-auto px-4 relative mt-4">
      <SkeletonTable v-if="!loaded" />
      <div v-if="receipts.length > 0">
        <Receipts v-if="loaded" class="hidden md:block" :receiptsArray="receipts" :pagination="pagination"
          :isLoadingNext="isLoadingNext" :isLoadingPrevious="isLoadingPrevious" :conversion_rates="conversion_rates"
          @getReceipts="getCompanyReceipts" />
        <ReceiptsMobile class="md:hidden" v-if="loaded" :isLoadingNext="isLoadingNext"
          :isLoadingPrevious="isLoadingPrevious" :receiptsArray="receipts" :pagination="pagination"
          :conversion_rates="conversion_rates" @getReceipts="getCompanyReceipts" />
      </div>
      <div v-show="loaded && receipts.length === 0">
        <div class="text-center">
          <h3 class="mt-2 text-sm font-medium text-gray-900">No receipts</h3>
        </div>
      </div>
    </div>
    
    <EditBusiness
        v-if="showEditBusinessModal"
        :dto="company"
        :isEdit="true"
        @fetchData="getCompany"
        @toggleModal="toggleEditBusinessModal"/>
    <ImageViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal"/>
  </div>
</template>

<script>
import store from "@/store";
import L from "leaflet";
import BusinessService from "@/service/business";
import ReceiptService from "@/service/receipt";
import FormatHelper from "@/helpers/format";
import Search from '@/components/search/SearchBanner.vue';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import Stats from '@/components/cards/Stats.vue';
import Receipts from '@/components/tables/Receipt.vue';
import ReceiptsMobile from '@/components/tables/mobile/Receipt.vue';
import SkeletonTable from '@/components/skeletons/Table';
import SkeletonCards from '@/components/skeletons/Cards';
import EditBusiness from "@/components/modals/business/Form.business";
import ImageViewer from "@/components/modals/receipt/ImageViewer";

export default {
  name: "Show.vue",
  components: {
    ImageViewer,
    EditBusiness,
    Search,
    Breadcrumb,
    Stats,
    Receipts,
    ReceiptsMobile,
    SkeletonTable,
    SkeletonCards,
  },
  data: () => ({
    company: {},
    receipts: [],
    allReceipts: [],
    filterType: null,
    page: 1,
    pageSize: 100,
    pagination: {
      show: 1,
      to: 100,
      totalItems: 0,
      pages: [],
      totalPages: 1,
    },
    id: 0,
    conversion_rates: {
      eur: store.getters.conversion.eur,
      usd: store.getters.conversion.usd
    },
    navigation: [
      {
        name: 'Home',
        url: 'Homepage',
        active: false,
      },
      {
        name: 'Business',
        url: 'BusinessShow',
        active: true,
      }
    ],
    imgUrl: '',
    showMoreCompany: false,
    showMore: false,
    showModal: false,
    isLoadingNext: false,
    isLoadingPrevious: false,
    authenticated: store.getters.authenticated,
    loaded: false,
    showEditBusinessModal: false,
    industries: [
      {
        id: 1,
        name: "Agriculture"
      },
      {
        id: 2,
        name: "Automotive"
      },
      {
        id: 3,
        name: "Banking"
      },
      {
        id: 4,
        name: "Chemical",
      },
      {
        id: 5,
        name: "Construction"
      },
      {
        id: 6,
        name: "Consulting"
      },
      {
        id: 7,
        name: "Education"
      },
      {
        id: 8,
        name: "Electronics"
      },
      {
        id: 9,
        name: "Energy"
      },
      {
        id: 10,
        name: "Entertainment"
      },
      {
        id: 11,
        name: "Financial Services"
      },
      {
        id: 12,
        name: "Food & Beverage"
      },
      {
        id: 13,
        name: "Government"
      },
      {
        id: 14,
        name: "Healthcare"
      },
      {
        id: 15,
        name: "Insurance"
      },
      {
        id: 16,
        name: "Manufacturing"
      },
      {
        id: 17,
        name: "Media"
      },
      {
        id: 18,
        name: "Non-Profit"
      },
      {
        id: 19,
        name: "Pharmaceutical"
      },
      {
        id: 20,
        name: "Real Estate"
      },
      {
        id: 21,
        name: "Retail"
      },
      {
        id: 22,
        name: "Technology"
      },
      {
        id: 23,
        name: "Telecommunications"
      },
      {
        id: 24,
        name: "Transportation"
      },
      {
        id: 25,
        name: "Travel"
      },
      {
        id: 26,
        name: "Other"
      }
    ],
  }),
  created: function() {
    this.id = this.$route.params.id;

    this.$root.$on('BusinessCreated', async () => {
      await this.getCompany();
    });

    this.$root.$on('FilterReceipts', async (type) => {
      await this.filter(type);
    });
  },
  beforeMount: async function() {
    await this.getCompany();
    await this.getCompanyReceipts(this.page, this.pageSize, false, false);
    this.loaded = true;
  },
  methods: {
    getCompany: async function() {
      const service = new BusinessService();
      await service.getCompanyById(this.id)
        .then((response) => {
          this.company = response.data;

          if (this.company.latitude && this.company.longitude)
            this.setMap();
            //   this.company.location = { lat: Number(this.company.latitude), lng: Number(this.company.longitude)  }
          this.setIndustry(this.company.industry);
        }).catch((e) => {
          console.log(e)
          console.log(e.request);
        });
    },
    getCompanyReceipts: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new ReceiptService();
      const cached = !this.authenticated;
      await service.getReceiptByCompanyId(page, pageSize, this.id, cached)
        .then((response) => {
          this.setCurrencyValues(response.data.items);
          this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        }).catch((e) => {
          console.log(e.request);
        }).finally(() => {
          if (loadingNext) {
            this.isLoadingNext = !this.isLoadingNext;
          } else if (loadingPrevious) {
            this.isLoadingPrevious = !this.isLoadingPrevious;
          }
        });
    },
    filter: async function(type) {
      // Filter is turned off
      if (type === null) {
        await this.getCompanyReceipts(this.page, this.pageSize, false, false);
        this.filterType = null;
      } else {
        // When there is no filter yet, copy list to allReceipts
        if (this.filterType === null) {
          this.allReceipts = this.receipts;
        }

        // Filter is changing
        if (this.filterType !== type) {
          this.receipts = this.allReceipts
        }
        if (type === 0) {
          await this.getCompanyReceipts(this.page, this.pageSize, false, false);
        }
        else if (type === 1) {
          const service = new ReceiptService();
          await service.getAllAssetsForAccount(this.page, this.pageSize, this.id)
            .then((response) => {
                this.setCurrencyValues(response.data.items);
                this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
              }).catch((e) => {
                console.log(e.request)
                console.log(`there is an error`, e);
              })
        }
        else if (type === 2) {
          const service = new ReceiptService();
          await service.getAllAdvancedPaymentsForAccount(this.page, this.pageSize, this.id)
              .then((response) => {
                this.setCurrencyValues(response.data.items);
                this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
              }).catch((e) => {
                console.log(e.request)
                console.log(`there is an error`, e);
              })
        }
        else if (type === 3) {
          await this.getCompanyReceipts(this.page, this.pageSize, false, false);
        }
        this.filterType = type;
      }
    },
    setMap: function() {
      const map = L.map('map', {
        center: [this.company.latitude, this.company.longitude],
        zoom: 13
      });
      L.marker([Number(this.company.latitude), Number(this.company.longitude)]).addTo(map);
      const tiles = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
      }).addTo(map);
    },
    setCurrencyValues: async function(receipts) {
      const helper = new FormatHelper();
      this.receipts = await helper.formatCurrencies(receipts);
    },
    setIndustry: function(id) {
      this.industries.forEach(industry => {
        if (industry.id === Number(id)) {
          this.company.industry = industry.name;
        }
      });
    },
    setPagination: function(page, pageSize, totalItems) {
      this.page = page;
      this.pageSize = pageSize;
      this.pagination.totalItems = totalItems;

      const pages = Math.ceil(this.pagination.totalItems / this.pageSize);
      this.pagination.pages = [];
      for (let i = 0; i < pages; i++) {
        this.pagination.pages.push(i+1);
      }

      this.pagination.totalPages = pages;
    },
    openLargeScreen: function(key) {
      console.log('click')
      const div = document.getElementById(`video-${key}`);
      console.log(div);
      if (div.requestFullscreen)
        div.requestFullscreen();
      else if (div.webkitRequestFullscreen)
        div.webkitRequestFullscreen();
      else if (div.msRequestFullScreen)
        div.msRequestFullScreen();
    },
    toggleModal: function(url = '') {
      this.imgUrl = url;
      this.showModal = !this.showModal;
    },
    toggleEditBusinessModal: function() {
      this.showEditBusinessModal = !this.showEditBusinessModal
    },
    formatUrl: function(url) {
      if (url !== null && url !== '' && (url.indexOf('http') === -1 || url.indexOf('https') === -1)) {
        url = 'https://' + url;
      }

      return url
    },
    truncateString: function(str, num) {
      if (str) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
  }
}
</script>

<style scoped>
</style>
