<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="">
          <h3 v-if="!isEdit" class="text-lg leading-6 font-medium text-gray-900">
            Add Employee
          </h3>
          <h3 v-else class="text-lg leading-6 font-medium text-gray-900">
            Edit Employee
          </h3>

          <EmployeeCreateForm v-if="loaded" :employeeFilled="employee" :isEdit="isEdit" :accounts="accounts"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeCreateForm from "@/components/form/employee/Form";
import AccountService from "@/service/account";
import EmployeeService from "@/service/employee";

export default {
  name: "EmployeeCreate",
  components: {
    EmployeeCreateForm
  },
  data: () => ({
    id: null,
    accounts: [],    
    employee:{},
    isEdit: false,
    loading: false,
    loaded: false
  }),
  created: async function() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.isEdit = true;
      await this.getEmployee();
    }
    await this.getAccountRights();
    this.loaded = true;
  },
  methods: {
    getEmployee: async function() {
      this.loading = true;
      await EmployeeService.get(this.id).then(response => {
        this.employee = response.data;
        this.loading = false;
      });
      this.loading = false;
    },
    getAccountRights: async function() {
      const service = new AccountService();
      // get list of accounts with edit rights for your company
      await service.getAccountRights().then(response => {
        this.accounts = response.data;
      });
    },
  }
}
</script>