<template>
  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      {{ label }}
      <span v-if="required" class="text-red-500 pl-1">*</span>
    </label>
    <div class="mt-1 sm:mt-0 sm:col-span-2">
      <div v-if="selected.name !== null && selected.id !== null" class="max-w-lg block">
        <div class="flex">
          <div class="truncate">
            Currently Selected {{ label }}:
            <span v-if="selectType !== 4" class="text-blue-500">{{ selected.name }}</span>
            <span v-else class="text-blue-500">{{ selected.txHash }}</span>
          </div>
          <div v-if="selectType === 4" class="flex-1 text-right">
            <button v-on:click="resetForReceipt" class="bg-red-500 float-right hover:bg-red-600 text-white rounded block px-2 py-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="max-w-lg mt-2 block">
        <input v-model="searchCategory"
               v-on:input="search"
               v-on:focus="search"
               type="text"
               required
               :placeholder="label"
               class="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md">

        <div v-if="records.length > 0 && results.length > 0" class="block mt-2 overflow-x-auto h-48">
          <div class="block" v-for="(category, i) in results" :key="i">
            <div v-on:click="selectForReceipt(category)"
                 :class="{'py-2 px-2 border-b border-t hover:bg-gray-200 cursor-pointer': !category.notSuggested,
                 'py-2 px-2 border-b border-t hover:bg-red-300 bg-red-200 cursor-pointer': category.notSuggested}">
              <div v-if="selectType !== 4">
                {{ category.name }}
              </div>
              <div v-else>
                <div class="grid grid-cols-10 gap-2 truncate">
                  <div class="col-span-1">
                    <span v-if="category.chainType === 0">
                       <img src="@/assets/icons/btc.svg" class="w-6"/>
                    </span>
                    <span v-if="category.chainType === 1">
                       <img src="@/assets/icons/eth.svg" class="w-6"/>
                    </span>
                    <span v-if="category.chainType === 2">
                       <img src="@/assets/icons/bnb.svg" class="w-6"/>
                    </span>
                  </div>
                  <div class="col-span-7">
                    <span>
                      {{ category.amount }}
                    </span>
                  </div>
                  <div v-if="category.ownerEmail" class="col-span-2">
                    {{ category.ownerEmail}}
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-2 truncate">
                  <div>
                    <span class="text-sm">{{ category.txHash }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectType === 4 && addCategory" class="mx-auto mt-2">
          <TxHashForm v-if="selectType === 4" :selectType="4" @getCategories="getCategories" @getSelectedCompany="getSelectedCompany" @getOwnerAccountId="getOwnerAccountId"/>
        </div>
        <div v-if="selectType === 1 && addCategory" class="mx-auto mt-2">
          <VendorForm v-if="selectType === 1" :selectType="1" @getCategories="getCategories" @getOwnerAccountId="getOwnerAccountId"/>
        </div>
        <div v-if="selectType === 2 && addCategory" class="mx-auto mt-2">
          <ExpenseCategoryForm v-if="selectType === 2" :selectType="2" @getCategories="getCategories" @getOwnerAccountId="getOwnerAccountId"/>
        </div>
        <div v-if="selectType === 3 && addCategory" class="mx-auto mt-2">
          <ExpenseForm v-if="selectType === 3" :selectType="3" @getCategories="getCategories" @getOwnerAccountId="getOwnerAccountId"/>
        </div>
        <div v-if="(selectType ===1 || selectType ===2 || selectType ===3 || selectType === 4) && !addCategory" class="w-1/2 mx-auto mt-2">
          <button v-on:click="addCategory = !addCategory" class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">
            Add New {{ label}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TxHashForm from "@/components/form/tx-hash/Form";
import VendorForm from "@/components/form/vendor/Form";
import ExpenseForm from "@/components/form/expense/Form";
import ExpenseCategoryForm from "@/components/form/expensecategory/Form";

export default {
  name: "CategorySearch",
  components: {
    TxHashForm,
    VendorForm,
    ExpenseForm,
    ExpenseCategoryForm
  },
  props: {
    label: {
      type: String,
      required: true
    },
    records: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    selectType: {
      type: Number,
      required: true
    },
    indexer: {
      type: Number,
      required: false,
      default: null
    },
    selectedCategory: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    results: [],
    searchCategory: '',
    selected: {
      id: null,
      name: null
    },
    addCategory: false,
  }),
  beforeMount: function() {
    // When component is edited, object is passed
    if (this.selectedCategory !== null) {
      this.selected = this.selectedCategory;
    }
  },
  methods: {
    search: function () {      
      if (this.searchCategory.length > 2) {
        this.results = [];
        const searchCategory = this.searchCategory.toLowerCase();
        this.records.forEach(record => {
          let rec  = record.name.toLowerCase();
          if (rec.includes(searchCategory)) {
            if (!this.results.includes(record))
              this.results.push(record);
          }
        });
      } else {
        this.results = this.records;
      }
    },
    getCategories: function(category) {
      this.addCategory = !this.addCategory;
      this.selectForReceipt(category);
      this.$emit('getCategory', this.selectType);
    },
    getSelectedCompany: function(callback){
      let res;
      this.$emit('getSelectedCompany', (x)=> res=x);
      callback(res);
    },
    getOwnerAccountId: function(callback){
      let res;
      this.$emit('getOwnerAccountId', (x)=> res=x);
      callback(res);
    },
    selectForReceipt: function(category) {
      this.addCategory = false;
      this.selected = category;
      this.searchCategory = '';
      this.results = [];
      this.$emit('setCategory', this.selected, this.selectType, this.indexer);
    },
    resetForReceipt: function() {
      this.selected = {
        id: null,
        name: null
      };
      this.searchCategory = '';
      this.results = [];
      this.$emit('resetCategory', this.selectType, this.indexer);
    },
    formatDollar: function(value) {
      let formatter;
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      return formatter.format(value);
    },
  },
  watch: {
    selectedCategory: function(newValue) {
      if (newValue !== null) {
        this.selected = newValue;
      }
    }
  }
}
</script>

<style scoped>

</style>
