<template>
  <div  class="fixed inset-0 overflow-y-auto z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div v-on:click="toggleModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white h-auto rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-2/3">
        <div class="sm:block absolute z-10 top-0 right-0 pt-4 pr-4">
          <button v-on:click="toggleModal" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="bg-white scale-150 overflow-y-auto sm:rounded-lg">
          <img :src="url" class="w-full" alt="">
<!--          <div id="img-zoom-lens" class="img-zoom-lens"></div>-->
<!--          <div class="grid grid-cols-2 gap-4 relative">-->
<!--            <div>-->
<!--              <div id="img-zoom-lens" class="img-zoom-lens" v-on:mousemove="moveLens($event)" v-on:touchmove="moveLens($event)"></div>-->
<!--              <img class="scale-150" id="receipt-image" v-on:mousemove="moveLens($event)" v-on:touchmove="moveLens($event)" :src="url" />-->
<!--            </div>-->
<!--            <div>-->
<!--              <div id="receipt-zoom-image" class="img-zoom-result w-full"></div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageViewer",
  props: {
    url: String
  },
  // mounted: function() {
  //   this.imageZoom("receipt-image", "receipt-zoom-image");
  // },
  methods: {
     imageZoom: function(imgID, resultID) {
       let img, lens, result, cx, cy;
       img = document.getElementById(imgID);
       result = document.getElementById(resultID);
       /* Create lens: */
       lens = document.getElementById("img-zoom-lens");

       /* Insert lens: */
       img.parentElement.insertBefore(lens, img);
       /* Calculate the ratio between result DIV and lens: */
       cx = result.offsetWidth / lens.offsetWidth;
       cy = result.offsetHeight / lens.offsetHeight;
       /* Set background properties for the result DIV */
       result.style.backgroundImage = "url('" + img.src + "')";
       result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
     },
    moveLens: function(e) {
      let pos, x, y;
      /* Prevent any other actions that may occur when moving over the image */
      e.preventDefault();
      /* Get the cursor's x and y positions: */
      pos = this.getCursorPos(e);
      const lens = document.getElementById("img-zoom-lens");
      /* Calculate the position of the lens: */
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      let img = document.getElementById("receipt-image");
      /* Prevent the lens from being positioned outside the image: */
      if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      if (x < 0) {x = 0;}
      if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      if (y < 0) {y = 0;}
      /* Set the position of the lens: */
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /* Display what the lens "sees": */
      const result = document.getElementById("receipt-zoom-image");
      const cx = result.offsetWidth / lens.offsetWidth;
      const cy = result.offsetHeight / lens.offsetHeight;
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    },
    getCursorPos: function(e) {
      let img = document.getElementById("receipt-image");
      let a, x = 0, y = 0;
      e = e || window.event;
      /* Get the x and y positions of the image: */
      a = img.getBoundingClientRect();
      /* Calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* Consider any page scrolling: */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
  },
  toggleModal: function() {
      this.$emit('toggleModal');
    }
  }
}
</script>

<style scoped>
/*.img-zoom-lens {*/
/*  position: absolute;*/
/*  border: 2px solid #3B82F6;*/
/*  !*set the size of the lens:*!*/
/*  width: 180px;*/
/*  height: 180px;*/
/*  border-radius: 90px;*/
/*}*/

/*.img-zoom-result {*/
/*  border: 1px solid #d4d4d4;*/
/*  !*set the size of the result div:*!*/
/*  height: 100%;*/
/*}*/
/*img:hover {*/
/*  transform: scale(2);*/
/*}*/
</style>
