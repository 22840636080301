<template>
  <div class="space-y-6 sm:space-y-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label :for="payment.method" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Payment Method
        <span class="text-red-500 pl-1">*</span>
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <select v-model="payment.method" required v-on:change="selectPaymentMethod($event)" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
          <option class="text-gray-500 mt-4 sm:text-sm" :value="0">Cash</option>
          <option class="text-gray-500 mt-4 sm:text-sm" :value="1">Credit Card</option>
          <option class="text-gray-500 mt-4 sm:text-sm" :value="2">Cheque</option>
          <option class="text-gray-500 mt-4 sm:text-sm" :value="3">Wire Transfer</option>
          <option class="text-gray-500 mt-4 sm:text-sm" :value="4">Cashier's check</option>
        </select>
      </div>
    </div>

    <div v-if="Number(payment.method) === 3" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label :for="payment.reference" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Reference
        <span class="text-red-500 pl-1">*</span>
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="max-w-lg mt-2 block">
          <input v-model="payment.reference"
                 type="text"
                 required
                 placeholder="Reference"
                 class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Payment",
  props: {
    selectedPayment: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    payment: {
      method: null, // 0 = Cash, 1 = Credit Card, 2 = Cheque, 3 = Wire Transfer
      reference: '',
    },
    showChains: false,
    itemSet: false,
  }),
  beforeMount: function() {
    if (this.selectedPayment && this.selectedPayment.method !== null) {
      this.payment = this.selectedPayment;
    } else {
      this.payment.method = 0; // Set default to cash
    }
  },
  methods: {
    selectPaymentMethod: function (event) {
      this.itemSet = false;
      this.payment.method = Number(event.target.value);
    },
  },
  watch: {
    payment: {
      handler: function (payment) {
        let isValid = false;
        // Default value
        if (Number(payment.method) === 0 || Number(payment.method) === 1 || Number(payment.method) === 2) {
          isValid = true;
        } else if (Number(payment.method) === 3) {
          // Wire Transfer
          isValid = payment.reference !== null && payment.reference !== '';
        }

        if (isValid && !this.itemSet) {
          this.itemSet = true;
          this.$emit('setPayment', this.payment);
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
