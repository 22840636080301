var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"min-w-full leading-normal"},[_vm._m(0),_c('tbody',_vm._l((_vm.companies),function(business,key){return _c('tr',{key:key},[_c('td',{staticClass:"px-5 py-5 border-b border-gray-200 bg-white text-sm"},[_c('div',{staticClass:"flex items-center"},[_vm._m(1,true),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"text-gray-900 whitespace-no-wrap"},[(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Homepage';
}) || _vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'BusinessPublicShow';
}))?_c('router-link',{attrs:{"to":{name: 'BusinessPublicShow', params: {id: business.id, name: _vm.formatName(business.name)}}}},[_vm._v(" "+_vm._s(business.name)+" ")]):(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Dashboard';
}) || _vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'BusinessShow';
})  || _vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Business';
}))?_c('router-link',{attrs:{"to":{name: 'BusinessShow', params: {id: business.id, name: _vm.formatName(business.name)}}}},[_vm._v(" "+_vm._s(business.name)+" ")]):_vm._e()],1)])])])])}),0)]),_c('div',{staticClass:"px-5 py-5  border-t flex flex-col xs:flex-row  xs:justify-between "},[_c('span',{staticClass:"text-xs xs:text-sm text-gray-900"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"page":_vm.page,"isLoadingNext":_vm.isLoadingNext,"isLoadingPrevious":_vm.isLoadingPrevious},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage,"goToPage":_vm.goToPage}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"},[_vm._v(" COMPANY NAME ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-shrink-0 w-10 h-10"},[_c('img',{staticClass:"w-full h-full rounded-full",attrs:{"src":require("@/assets/img/blockchain.png"),"alt":""}})])}]

export { render, staticRenderFns }