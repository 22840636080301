<template>
  <div class="max-w-7xl mx-auto bg-white sm:px-6 relative">
    <div class="block" v-for="(company, key) in companies" :key="key">
      <div class="border-b">
        <div v-on:click="goToCompany(company.id, formatName(company.name))" class="cursor-pointer hover:bg-gray-100 px-4 py-4">
          <div class="flex">
            <div class="flex-1">
              <div class="pl-2">
                <span>{{company.name}}</span>
              </div>
            </div>
<!--            <div>-->
<!--              <div class="flex">-->
<!--                <svg v-if="!company.showFull" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />-->
<!--                </svg>-->
<!--                <svg  v-if="company.showFull" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />-->
<!--                </svg>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <div v-if="company.showFull" v-on:click="company.showFull = !company.showFull" class="pt-2 cursor-pointer">
          <router-link
              v-if="$route.matched.some(({ name }) => name === 'Homepage')"
              :to="{name: 'BusinessPublicShow', params:{id: company.id, name: formatName(company.name)}}"
              class="text-center text-blue-500 block mt-8">
            Show company
          </router-link>
          <router-link
              v-else-if="$route.matched.some(({ name }) => name === 'Dashboard') || $route.matched.some(({ name }) => name === 'Business')"
              :to="{name: 'BusinessShow', params:{id: company.id, name: formatName(company.name)}}"
              class="text-center text-blue-500 block mt-8">
            Show company
          </router-link>
          <div v-if="$route.matched.some(({ name }) => name === 'Business')" class="mt-4">
            <span v-on:click="editBusiness(company)" class="cursor-pointer text-center block text-blue-500 hover:text-blue-600">Edit company</span>
          </div>
        </div>
      </div>
    </div>

    <Pagination
        :pagination="pagination"
        :page="page"
        :isLoadingNext="isLoadingNext"
        :isLoadingPrevious="isLoadingPrevious"
        @nextPage="nextPage" @previousPage="previousPage" />
  </div>
</template>

<script>
import Pagination from "@/components/tables/Pagination.vue";

export default {
name: "Business.vue",
  props: {
    companyArray: Array,
    pagination: Object,
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
  },
  components: {
    Pagination
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    companies: function() {
      return this.companyArray;
    },
  },
  methods: {
    goToCompany: function(id, compName) {
      if (this.$route.matched.some(({ name }) => name === 'Homepage')) {
        this.$router.push({name: 'BusinessPublicShow', params: {id: id, name:compName}});
      } else if (this.$route.matched.some(({ name }) => name === 'Dashboard') || this.$route.matched.some(({ name }) => name === 'Business')) {
        this.$router.push({name: 'BusinessShow', params: {id: id, name:compName}});
      }
    },
    editBusiness: function(business) {
      this.$emit('editBusiness', business)
    },
    formatName: function(name) {
      for (let i = 0; i < name.length; i++) {
        name = name.replace(" ", "-");
      }
      return name.toLowerCase();
    },
    nextPage: function() {
      this.page++;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getCompanies', this.page, 25, true, false);
    },
    previousPage: function() {
      this.page--;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.show === 0)
        this.pagination.show = 1;

      if (this.pagination.to < 25)
        this.pagination.to = 25;

      this.$emit('getCompanies', this.page, 25, false, true);
    },
  }
}
</script>

<style scoped>

</style>
