<template>
  <div>
    
    <Search v-if="!authenticated" />
    <div class="max-w-screen-2xl mx-auto px-4 sm:px-6 relative mt-6">
      <Breadcrumb :navigation="navigation" />
    </div>
    <SkeletonReceipt v-if="!loaded" />

    <div v-if="loaded" class="max-w-screen-2xl mx-auto px-4 py-1 relative m-3">
      <div class="block bg-white px-4 py-1 rounded-lg" v-bind:class="{'rounded-md border border-gray-200': $route.matched.some(({ name }) => name === 'ReceiptShow')}">
      <h3 class="nNamComp">{{company.name}}</h3>

      <div class="block lg:flex">
        <div class="block lg:flex-auto w-full">
          <div class="flex nLiResc"><span>Vendor :</span><span class="nLiSho">{{ receipt.vendorName }}</span></div>
          <div class="flex nLiResc"><span>Created :</span><span class="nLiSho">{{ formatDate(receipt.created) }}</span></div>
          <div class="flex nLiResc"><span>Receipt ID :</span><span class="nLiSho">{{ receipt.id }}</span></div>
          <div class="flex nLiResc" v-if="receipt.paymentMethod !== null"><span>Payment Method :</span>
                  <span class="nLiSho" v-if="receipt.paymentMethod === 0">Cash</span>
                  <span class="nLiSho" v-if="receipt.paymentMethod === 1">CreditCard</span>
                  <span class="nLiSho" v-if="receipt.paymentMethod === 2">Cheque</span>
                  <span class="nLiSho" v-if="receipt.paymentMethod === 3">WireTransfer</span>
                  <span class="nLiSho" v-if="receipt.paymentMethod === 4">Cashier's check</span>
          </div>
          <div class="flex nLiResc" v-if="receipt.amount" ><span>Amount Paid :</span><span class="nLiSho">{{ formatDollar(receipt.amount) }}</span></div>
          <div class="flex nLiResc" v-if="receipt.paymentMethod && receipt.paymentMethod === 3" ><span>Reference :</span><span class="nLiSho">{{receipt.paymentReference }}</span></div>

          <div class="flex nLiResc" v-if="receipt.chainType !== null"><span>Chain Type :</span>  
          <div class="flex">
                    <div class="nLiSho flex">
                    <div v-if="receipt.chainType === 0">
                      <img src="@/assets/icons/btc.svg" class="w-6"/>
                    </div>
                    <div v-if="receipt.chainType === 1">
                      <img src="@/assets/icons/eth.svg" class="w-6"/>
                    </div>
                    <div v-if="receipt.chainType === 2">
                      <img src="@/assets/icons/bnb.svg" class="w-6"/>
                    </div>
                    <div v-if="receipt.chainType === 3">
                      <img src="@/assets/icons/tron.svg" class="w-6"/>
                    </div>
                    <div v-if="receipt.chainType === 0"> BTC</div>
                    <div v-if="receipt.chainType === 1"> ETH</div>
                    <div v-if="receipt.chainType === 2"> BNB</div>
                    <div v-if="receipt.chainType === 3"> TRX</div>
                    </div>
          </div>
          </div>
          <div class="flex nLiResc" v-if="receipt.txHash"><span>Transaction Hash URL :</span>
               <div v-if="(receipt.chainType === 0 && receipt.txHash) ||
              (receipt.chainType === 1 && receipt.txHash) ||
              (receipt.chainType === 2 && receipt.txHash)||
              (receipt.chainType === 3 && receipt.txHash)">
                  <div class="hidden md:block">
                    <span v-if="receipt.chainType === 0">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://blockchain.com/btc/tx/${receipt.txHash}`"
                         target="_blank">{{ receipt.txHash }}</a>
                    </span>
                    <span v-if="receipt.chainType === 1">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://etherscan.io/tx/${receipt.txHash}`"
                         target="_blank">{{ receipt.txHash }}</a>
                    </span>
                    <span v-if="receipt.chainType === 2">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://bscscan.com/tx/${receipt.txHash}`"
                         target="_blank">{{ receipt.txHash }}</a>
                    </span>
                    <span v-if="receipt.chainType === 3">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://tronscan.org/#/transaction/${receipt.txHash}`"
                         target="_blank">{{ receipt.txHash }}</a>
                    </span>
                  </div>
                  <div class="block md:hidden">
                    <span v-if="receipt.chainType === 0">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://blockchain.com/btc/tx/${receipt.txHash}`"
                         target="_blank">{{ truncateString(receipt.txHash, 28) }}</a>
                    </span>
                    <span v-if="receipt.chainType === 1">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://etherscan.io/tx/${receipt.txHash}`"
                         target="_blank">{{ truncateString(receipt.txHash, 28) }}</a>
                    </span>
                    <span v-if="receipt.chainType === 2">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://bscscan.com/tx/${receipt.txHash}`"
                         target="_blank">{{ truncateString(receipt.txHash, 28) }}</a>
                    </span>
                    <span v-if="receipt.chainType === 3">
                      <a class="text-blue-500 nLiSho"
                         :href="`https://tronscan.org/#/transaction/${receipt.txHash}`"
                         target="_blank">{{ truncateString(receipt.txHash, 28) }}</a>
                    </span>
                  </div>
               </div>
          </div>
          <div class="flex nLiResc"><span>Receipt Hash :</span>
           <span class="hidden md:block nLiSho">{{ receipt.identifier }}</span>
           <span class="block md:hidden nLiSho">{{ truncateString(receipt.identifier, 28) }}</span>
          </div>
          <div class="flex nLiResc " v-if="receipt.customFields && receipt.customFields.length > 0">
            <div v-for="(field, key) in receipt.customFields" :key="key">
              <div>
                {{ field.name }}
              </div>
              <div>
                <p>
                  {{ field.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden lg:block lg:flex-auto w-full">
          <div class="nbQr"> 
             <img id="qr-code" class="h-56 w-56 mt-2 qrCodeRec mb-12 lg:mb-0" src="" :alt="`QR code for ${company.name}`">
          </div>
        </div>
        <div class="block lg:hidden">
          <img class="h-56 w-56 mt-2 mb-12 lg:mb-0" :src="qrUrl" :alt="`QR code for ${company.name}`">
        </div>
      </div>

      <div class="space-y-8 divide-y mt-8 divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <hr v-if="receipt.items && receipt.items.length > 0" class="mt-8">
              <ReceiptItems v-if="receipt.items && receipt.items.length > 0" :items="receipt.items" :receipt="receipt"/>
              <p></p>
              
              <hr class="mt-8"> 
              <div v-if="receipt.receiptDocuments && receipt.receiptDocuments.length > 0" class="grid grid-cols-4 gap-4 mt-8">
                <div class="col-span-2">
                  <div class="block text-purple-500">
                    Receipt Documents
                  </div>
                  <div class="block mt-2">
                    <div v-for="(file, key) in receipt.receiptDocuments" :key="key">
                      <a :href="file" target="_blank" class="relative z-20">
                      <object :data="file" class="w-full" height="650px"></object>
<!--                        {{file}}-->
<!--                        <embed class="w-full z-10" :src="`${file}`" height="650px" />-->
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-4 gap-4 mt-8">
                <div class="col-span-2">
                  <div v-if="receipt.proofOfPaymentImages && receipt.proofOfPaymentImages.length > 0" :class="{'mt-4': receipt.proofOfPaymentImages.length > 0}">
                    <div class="block text-purple-500">
                      Invoice
                    </div>
                    <div class="block mt-2">
                      <div class="flex overflow-x-auto">
                        <div v-for="(file, key) in receipt.proofOfPaymentImages" :key="key">
                          <div class="w-56 h-36 bg-cover mr-5 rounded-md">
                            <img :src="file"
                                 v-on:click="toggleModal(file)" class="w-full hover:scale-150 h-full bg-cover rounded-md cursor-pointer">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="receipt.receiptImages && receipt.receiptImages.length > 0" :class="{'mt-4': receipt.receiptImages}">
                    <div class="block text-purple-500">
                      Receipt / Proof of Payment
                    </div>
                    <div class="block mt-2">
                      <div class="flex overflow-x-auto">
                        <div v-for="(file, key) in receipt.receiptImages" :key="key">
                          <div class="w-56 h-36 bg-cover mr-5 rounded-md">
                            <img :src="file"
                                 v-on:click="toggleModal(file)" class="w-full hover:scale-150 h-full bg-cover rounded-md cursor-pointer">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="block mt-8">
                <div v-if="authenticated" class="pt-5">
                  <div class="flex">
                    <div v-if="receipt.canEdit" class="justify-end">
                      <router-link :to="{name: 'ReceiptEdit', params: {id: id}}" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Edit Receipt
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
      </div> 

        <nav class="bg-white mt-8 px-4 py-3 flex items-center justify-center border-t border-gray-200 sm:px-6" aria-label="Pagination">
          <div v-if="authenticated" class="flex-1 flex justify-center">
            <a v-if="receipt.id > 1" :href="`/cp/receipt/${receipt.id - 1}`" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Previous Receipt
            </a>
            <a :href="`/cp/receipt/${receipt.id + 1}`" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Next Receipt
            </a>
          </div>
          <div v-else-if="!authenticated" class="flex-1 flex justify-center">
            <a v-if="receipt.id > 1" :href="`/receipt/${receipt.id - 1}`" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Previous Receipt
            </a>
            <a :href="`/receipt/${receipt.id + 1}`"  class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Next receipt
            </a>
          </div>
        </nav>
      </div>
      <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal"/>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import ReceiptService from "@/service/receipt";
import Search from '@/components/search/SearchBanner.vue';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import ImgViewer from '@/components/modals/receipt/ImageViewer';
import SkeletonReceipt from '@/components/skeletons/Receipt';
import BusinessService from "@/service/business";
import ReceiptItems from "@/components/pages/receipt/Items";

export default {
  name: "ReceiptShow",
  components: {
    ReceiptItems,
    Search,
    Breadcrumb,
    ImgViewer,
    SkeletonReceipt,
  },
  data: () => ({
    receipt: {
      invoiceNumber: null,
    },
    company: {},
    qrCode: null,
    index: 0,
    accessToken: '',
    copied: false,
    id: 0,
    imgUrl: '',
    qrUrl: '',
    showModal: false,
    authenticated: store.getters.authenticated,
    navigation: [],
    loaded: false,
  }),
  created: function() {
    this.id = this.$route.params.id;
  },
  beforeMount: async function() {
    await this.getReceipt();
    await this.getCompany();
    this.loaded = true;
    await this.getQrCode();

    if (this.authenticated) {
      this.navigation = [
        {
          name: 'Home',
          url: 'Homepage',
          params: null,
          active: false,
        },
        {
          name: this.company.name,
          url: 'BusinessShow',
          params: {
            id: this.company.id,
            name: this.formatName(this.company.name)
          },
          active: false,
        },
        {
          name: 'Receipt',
          url: 'ReceiptPublicShow',
          params: null,
          active: true,
        }
      ];
    } else {
      this.navigation = [
        {
          name: 'Home',
          url: 'Homepage',
          params: null,
          active: false,
        },
        {
          name: this.company.name,
          url: 'BusinessPublicShow',
          params: {
            id: this.company.id,
          },
          active: false,
        },
        {
          name: 'Receipt',
          url: 'ReceiptPublicShow',
          params: null,
          active: true,
        }
      ];
    }
  },
  methods: {
    getReceipt: async function() {
      const service = new ReceiptService();
      let query = null;
      if (this.$route.query && this.$route.query.accessToken) {
        query = this.$route.query.accessToken;
      }
      await service.getReceiptById(this.id, query)
        .then((response) => {
          this.receipt = response.data;
        }).catch((e) => {
          if (e.request.status === 404) {
            this.$router.push({name: 'NotFound'});
          } else if (e.request.response) {
            const msg = JSON.parse(e.request.response)
            this.$notifications('Something Went Wrong', msg.message, '', 1, true);
          }
        })
    },
    getCompany: async function() {
      const service = new BusinessService();
      await service.getCompanyById(this.receipt.companyId)
        .then((response) => {
          this.company = response.data;
        })
    },
    getQrCode: async function() {
      const service = new ReceiptService();
      await service.getQrCode(this.receipt.id)
        .then(response => {
          this.qrUrl = response.data;
          document.getElementById(`qr-code`).setAttribute('src', response.data);
        });
    },
    formatDate: function(date) {
      const utcDate = new Date(date).toUTCString();
      return new Date(utcDate)
        .toLocaleDateString(
          [],
          {
            day: 'numeric',
            year: 'numeric',
            month: 'short',
          });
    },
    formatDollar: function(value) {
      if (this.receipt.currency === 0) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AED',
          minimumFractionDigits: 2
        }).format(value);
      } else if (this.receipt.currency === 1) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(value);
      } else if (this.receipt.currency === 2) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }).format(value);
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AED',
          minimumFractionDigits: 2
        }).format(value);
      }
    },
    toggleModal: function(url = '') {
      this.imgUrl = url;

      this.showModal = !this.showModal;
    },
    truncateString: function(str, num) {
      if (str !== undefined) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
    formatName: function(name) {
      for (let i = 0; i < name.length; i++) {
        name = name.replace(" ", "-");
      }
      return name.toLowerCase();
    },
  },
}
</script>

<style scoped>

.router-link-exact-active {
  color: #4F46E5;
}
.directions {
  direction: ltr;
}
</style>
