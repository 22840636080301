import axios from "axios";
import store from "@/store";

export default class AccountService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
        }
    }

    async getProfile() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/account/get`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getAccountRights() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/account/get-edit-right-accounts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getPermissions() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/account/get-user-permissions`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getGrantedPermissions() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/account/get-granted-user-permissions`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async grantPermission(dto: Record<string, any>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/add-user-permission`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async editPermission(dto: Record<string, any>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/update-user-permission`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async deletePermission(dto: Record<string, any>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/delete-user-permission`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async deactivateToken(id: number, type: number) {
        const dto = {
            id: id,
            type: type
        }
        return await axios.put(`${process.env.VUE_APP_SERVICE}/account/delete-access-token`, dto,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async updateProfile(dto: Record<string, any>) {
        return await axios.put(`${process.env.VUE_APP_SERVICE}/account/update`, dto,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
