import axios from "axios";
import store from "@/store";

export default class AuthenticateService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
        }
    }

    async authenticate(dto: Record<string, unknown>) {

        return await axios.post(`${process.env.VUE_APP_SERVICE}/authentication/authenticate`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async register(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/register`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async verifyAccount(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/verify-email`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async forgotPassword(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/forgot-password`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async setPassword(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/account/reset-password`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async refreshToken(token: string) {
        const dto = {
            token: token
        }
        return await axios.post(`${process.env.VUE_APP_SERVICE}/authentication/refresh-token/`, dto, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async logout() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/token/sign-out/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
