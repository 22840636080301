import axios from "axios";
import store from "@/store";

export default class PaymentService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    async add(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/payment/add`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async edit(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/payment/edit`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async all(page: number, pageSize: number, companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/payment/lookup?companyId=${companyId}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/payment/all?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async get(id: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/payment/${id}`,  {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async delete(id: number) {
        return await axios.delete(`${process.env.VUE_APP_SERVICE}/payment/delete/${id}`,  {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
