<template>
  <div>
    <div class="relative bg-white">
      <div class="max-w-screen-2xl mx-auto px-4">
        <div class="flex justify-between items-center py-2 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link :to="{name: 'Homepage'}">
              <span class="sr-only">Workflow</span>
              <img class="h-12 w-auto" src="@/assets/logo.png" alt="Accountant Chain">
            </router-link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <button v-on:click="menuToggled = !menuToggled" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
              <span class="sr-only">Open menu</span>
              <!-- Heroicon name: outline/menu -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav class="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
            <router-link v-if="!isAuthenticated" :to="{name: 'Login'}" class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </router-link>
            <router-link v-if="!isAuthenticated" :to="{name: 'Register'}" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500 transition duration-300 ease-in-out ">
              Sign up
            </router-link>
            <div v-if="isAuthenticated">
              <span class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-700">
                Profile
              </span>
            </div>
          </nav>
        </div>
      </div>

      <div v-if="menuToggled" class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-12 w-auto" src="@/assets/logo.png" alt="Accountant Chain">
              </div>
              <div class="-mr-2">
                <button v-on:click="menuToggled = !menuToggled" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div class="grid grid-cols-2 gap-y-4 gap-x-8">

            </div>
            <div>
              <router-link :to="{name: 'Register'}" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500 transition duration-300 ease-in-out">
                Sign up
              </router-link>
              <p class="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <router-link :to="{name: 'Login'}" class="text-blue-600 hover:text-blue-500">
                  Sign in
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "Header",
  data: () => ({
    isAuthenticated: store.getters.authenticated,
    menuToggled: false,
  }),
  mounted: async function() {
    this.$root.$on('UserSignedIn', async () => {
      await this.setAuthentication();
    });
  },
  methods: {
    setAuthentication: async function() {
      this.$store.state.authenticated = true;
      this.isAuthenticated = true;
    }
  }
}
</script>
