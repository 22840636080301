<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed inset-0 overflow-y-auto z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div v-on:click="toggleModal"  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
          <button v-on:click="toggleModal" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="bg-white overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Import excel sheet
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Create receipts in an instance by uploading your excel sheet
            </p>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  Select company to import receipts
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <select required v-model="companyId" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    <option selected disabled :value="null">Select business</option>
                    <option v-for="(company, key) in companies" :key="key" :value="company.id">{{company.name}}</option>
                  </select>
                </dd>
              </div>
            </dl>
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="font-medium text-gray-800">
                  File
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div>
                    <input type="file" v-on:change="chooseFile" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Company name">
                  </div>
                </dd>
              </div>
            </dl>
          </div>
          <div class="sm:py-5 text-center sm:px-6">
            <button v-if="!loading && file.name !== '' && companyId !== null" v-on:click="uploadFile" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Upload Excel
            </button>
            <button v-else-if="!loading" class="inline-flex cursor-not-allowed opacity-70 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Upload Excel
            </button>
            <button v-else-if="loading" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Upload Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReceiptService from "@/service/receipt";
import BusinessService from "@/service/business";

export default {
  name: "ImportExcel",
  data: () => ({
    companies: [],
    companyId: null,
    file: {
      name: ''
    },
    loading: false,
    hasResponse: false,
  }),
  beforeMount: async function() {
    await this.getAccountCompanies()
  },
  methods: {
    getAccountCompanies: async function() {
      const service = new BusinessService();
      await service.getAccountCompanies(1, 1000)
        .then((response) => {
          this.companies = response.data.items;
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
    chooseFile: function(event) {
      const files = event.target.files;
      this.file.name = files[0].name;
      this.file = files[0];
    },
    uploadFile: async function() {
      this.loading = !this.loading;
      let formData = new FormData();
      formData.append('file', this.file, this.file.name);
      // formData.append('companyId', this.companyId);
      const service = new ReceiptService();
      await service.uploadExcel(formData, this.companyId)
        .then(() => {
          this.$notifications(
              `Excel Successfully Uploaded`,
              `The receipts are automatically created`,
              '',
              0,
              true
          );
          this.loading = !this.loading;
          this.$emit('toggleModal');
          this.$router.push({name: 'Receipt'});
        }).catch((e) => {
          console.log(e.request)
            this.$notifications(
              `Excel Upload Failed`,
              `Something went wrong, please try again.`,
              '',
              1,
              true
            );
        }).finally(() => {
          this.loading = !this.loading;
        });
    },
    toggleModal: function() {
      this.$emit('toggleModal');
    }
  }
}
</script>

<style scoped>

</style>
