<template>
  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
    <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Custom Fields
    </label>
    <div class="mt-1 sm:mt-0 sm:col-span-2">
      <div v-if="isEdit && existingFields && existingFields.length > 0">
        <div class="max-w-lg" v-for="(field, key) in existingFields" :key="key">
          <div :class="{'mt-6': key > 0}">
            <div>
              <input v-model="field.name"
                     type="text"
                     required
                     placeholder="Field name"
                     class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="mt-2">
              <input v-model="field.value"
                     type="text"
                     required
                     placeholder="Field value"
                     class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
            </div>
            <button v-on:click="deleteCustomField(key, true)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div :class="{'mt-6': isEdit && existingFields.length > 0}">
        <div class="max-w-lg " v-for="(field, key) in customFields" :key="key">
          <div :class="{'mt-6': key > 0}">
            <select v-if="existingsCustomFields.length > 0" required v-on:change="setCustomField($event, key)" class="block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option selected disabled :value="null">Select Custom Field</option>
              <option v-for="(field, key) in existingsCustomFields" :key="key" :value="field">{{field}}</option>
            </select>
            <div v-if="!field.isExisting" :class="{'mt-4': existingsCustomFields.length > 0}">
              <input v-model="field.name"
                     type="text"
                     required
                     placeholder="Field name"
                     class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="mt-2">
              <input v-model="field.value"
                     type="text"
                     required
                     placeholder="Field value"
                     class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
            </div>
            <button v-on:click="deleteCustomField(key)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        </div>
        <div class="max-w-lg pt-5 text-center">
          <button v-on:click="addCustomFields" class="bg-blue-500 mx-auto cursor-pointer hover:bg-blue-600 px-3 py-2 rounded-lg flex text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white " fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="pl-2">Add Custom Field</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReceiptService from "@/service/receipt";

export default {
  name: "CustomFields",
  props: {
    indexer: {
      type: Number,
      required: false,
      default: null
    },
    existingFields: {
      type: Array,
      required: false,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    companyId: {
      type: Number || String,
      required: false,
      default: null
    }
  },
  data: () => ({
    existingsCustomFields: [],
    customFields: [],
    itemSet: false,
  }),
  created: async function() {
    const service = new ReceiptService();
    await service.getCustomFields(Number(this.companyId)).then(response => {
      this.existingsCustomFields = response.data;
    });
  },
  methods: {
    addCustomFields: function(existing = false) {
      const field = {
        name: '',
        value: '',
        isExisting: false,
      };

      this.customFields.push(field);
      this.itemSet = false;
    },
    deleteCustomField: function(key, existing = false) {
      if (existing) {
        this.$emit('deleteCustomField', key, this.indexer);
      } else {
        this.itemSet = false;
        this.customFields.splice(key, 1);
      }
    },
    setCustomField: function(event, key) {
      const field = event.target.value;
      this.customFields[key].name = field;
      this.customFields[key].isExisting = true;
    },
  },
  watch: {
    customFields: {
      handler: function(items) {
        let isValid = false;
        if (items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            const item = items[i];

            isValid = (item.name !== null && item.name !== '' && item.name !== undefined) &&
                (item.value !== '' && item.value !== null && item.value !== undefined);
          }
        }

        if (isValid && !this.itemSet) {
          this.itemSet = true;
          this.$emit('setCustomFields', this.customFields, this.indexer);
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
