<template>
  <div :class="{
    'sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5': uploadType === 6 || uploadType === 7 || uploadType === 8,
    'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5': uploadType !== 6 && uploadType !== 7 && uploadType !== 8,
  }">
    <div>
      <label v-if="uploadType !== 6 && uploadType !== 7 && uploadType !== 8" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {{ label }}
      </label>
      <label v-else class="pl-6 font-medium text-gray-800">
        {{ label }}
      </label>
      <div v-if="uploadType === 2" class="block text-sm text-gray-700">
        <div :class="{
          'sm:grid sm:grid-cols-4 sm:gap-4': uploadType === 6 || uploadType === 7 || uploadType === 8,
          'sm:grid sm:grid-cols-3 sm:gap-4': uploadType !== 6 && uploadType !== 7 && uploadType !== 8,
        }">
          <!-- <dt class="text-gray-700 col-span-2">
            Use the scanner for this receipt(s)
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
            <div>
              <input v-on:click="useScanner = !useScanner" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
            </div>
          </dd> -->
        </div>
      </div>
    </div>
    <div :class="{
      'mt-1 sm:mt-0 sm:col-span-3 px-5 pb-5': uploadType === 6 || uploadType === 7 || uploadType === 8,
      'mt-1 sm:mt-0 sm:col-span-2': uploadType !== 6 && uploadType !== 7 && uploadType !== 8,
    }">
      <div v-if="selectedFiles && selectedFiles.length > 0" class="inline-block min-w-full">
        <div v-if="showFiles" class="max-w-lg mb-4 flex overflow-x-auto">
          <div v-for="(file, key) in selectedFiles" :key="key">
            <div v-if="file !== null" class="w-56 mr-5 rounded-md">
              <video v-if="uploadType === 5 || uploadType === 7" controls class="rounded-md">
                <source :src="file" type="video/mp4">
                <source :src="file" type="video/ogg">
              </video>
              <img :src="file"
                   v-else-if="uploadType !== 3 && uploadType !== 5 && uploadType !== 7 && uploadType !== 8"
                   v-on:click="toggleModal(file)" class="w-full h-36 rounded-md cursor-pointer">
              <div v-else-if="uploadType === 8" class="text-center">
                Company License
              </div>
              <span v-else>
                Receipt-document-{{key+1}}.pdf
              </span>
              <button v-on:click="deleteFile(key, true)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div v-if="uploadType === 2 && imgLoading">
          <span class="absolute items-center">
            <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        </div>
        <div v-else-if="!imgLoading" class="space-y-1 text-center">
          <svg v-if="fileArray.length === 0" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <div class="flex text-sm text-gray-600">
            <label v-if="fileArray.length === 0" :for="divId" class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-600">
              <span>{{uploadTitle}}</span>
              <input v-on:change="selectFile" v-if="uploadType === 1 || uploadType === 2 || uploadType === 4 || uploadType === 6" accept="image/*" :id="divId" multiple type="file" class="sr-only">
              <input v-on:change="selectFile" v-if="uploadType === 3" accept="application/pdf" :id="divId" multiple type="file" class="sr-only">
              <input v-on:change="selectFile" v-if="uploadType === 5 || uploadType === 7" accept="video/mp4" :id="divId" multiple type="file" class="sr-only">
              <input v-on:change="selectFile" v-if="uploadType === 8" accept="application/pdf,image/*" :id="divId" type="file" class="sr-only">

            </label>
            <div class="block">
              <div v-if="uploadType === 2 && scannedImages.length > 0">
                <div v-for="(product, key) in scannedImages" :key="key" class="text-blue-600 font-medium block">
                  <div class="block mt-2">
                    <img :src="product" :id="`scannedImg-${key}`" />
                    <button v-on:click="deleteFile(key, false, true)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="(uploadType === 1 || uploadType === 2 || uploadType === 3 || uploadType === 4 || uploadType === 6 || uploadType === 8) && scannedImages.length === 0">
                <div v-for="(file, key) in fileArray" :key="key" class="text-blue-600 font-medium block">
                  <div class="block">
                    {{file.name}}
                    <img v-if="(uploadType === 1 || uploadType === 6) && file.name !== ''" :id="`category-receiptImg-${key}`"/>
                    <img v-if="uploadType === 2 && file.name !== ''" :id="`category-popImg-${key}`"/>
                    <img v-if="uploadType === 4 && file.name !== ''" :id="`category-prodImg-${key}`"/>
                    <img v-if="uploadType === 8 && isImgType && file.name !== ''" :id="`category-licenseImg-${key}`">
                    <video v-if="(uploadType === 5 || uploadType === 7) && file.name !== ''" controls :id="`category-prodVid-${key}`"></video>
                    <button v-if="file.name !== ''" v-on:click="deleteFile(key)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="(uploadType === 5 || uploadType === 7) && scannedImages.length === 0">
                <div v-for="(file, key) in fileArray" :key="key" class="text-blue-600 font-medium block">
                  <div class="block">
                    {{file.name}}
                    <video controls :id="`category-prodVid-${key}`"></video>
                    <button v-if="file.name !== ''" v-on:click="deleteFile(key)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal"/>
  </div>
</template>

<script>
import ReceiptService from "@/service/receipt";
import ImgViewer from '@/components/modals/receipt/ImageViewer';

export default {
  name: "ReceiptFiles",
  components: {
    ImgViewer
  },
  props: {
    label: {
      type: String,
      required: true
    },
    uploadTitle: {
      type: String,
      required: true,
    },
    uploadType: {
      type: Number,
      required: true,
    },
    divId: {
      type: String,
      required: true,
    },
    indexer: {
      type: Number,
      required: false,
      default: null
    },
    selectedFiles: {
      type: Array,
      required: false,
      default: null
    },
  },
  data: () => ({
    showFiles: false,
    fileArray: [],
    useScanner: false,
    scannedImages: [],
    imgLoading: false,
    hasScannedReceipt: false,
    imgUrl: null,
    showModal: false,
    isImgType: false, // when business license is img instead of pdf
  }),
  created: function() {
    this.showFiles = true;
  },
  methods: {
    selectFile: async function(event) {
      this.fileArray = [];
      if (this.useScanner) {
        this.imgLoading = !this.imgLoading;
        this.scannedImages = [];
        const files = event.target.files;
        for(let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('file', files[i], files[i].name);

          const service = new ReceiptService();
          await service.getImageReceipt(formData)
            .then(response => {
              this.hasScannedReceipt = true;
              const url = window.URL.createObjectURL(new Blob([response.data]));
              this.scannedImages.push(url);

              let blob = new Blob([response.data]);
              let file = this.blobToFile(blob, files[i].name);
              this.fileArray.push(file);
            }).catch((e) => {
              console.log(e.request);
            });
        }

        this.imgLoading = !this.imgLoading;
        this.appendFiles();
      } else {
        const files = event.target.files;
        let uploadType = this.uploadType;
        for (let i = 0; i < files.length; i++) {
          this.fileArray.push(files[i]);

          if (uploadType !== 3) {
            let reader = new FileReader();
            reader.onload = function (e) {
              if (uploadType === 1 || uploadType === 6) {
                document.getElementById(`category-receiptImg-${i}`).setAttribute('src', e.target.result);
              } else if (uploadType === 2) {
                document.getElementById(`category-popImg-${i}`).setAttribute('src', e.target.result);
              } else if (uploadType === 4) {
                document.getElementById(`category-prodImg-${i}`).setAttribute('src', e.target.result);
              } else if (uploadType === 5 || uploadType === 7) {
                document.getElementById(`category-prodVid-${i}`).setAttribute('src', e.target.result);
              }
            }
            reader.readAsDataURL(files[i]);
          }
        }
      }

      this.$emit('setFiles', this.fileArray, this.uploadType, this.indexer);
    },
    appendFiles: function() {
      for(let i = 0; i < this.scannedImages.length; i++) {
        document.getElementById(`scannedImg-${i}`).setAttribute('src', this.scannedImages[i]);
      }
    },
    deleteFile: async function(key, existingFiles = false, scannedImg = false) {
      if (existingFiles) {
        this.showFiles = false;
        await this.$emit('deleteFile', key, this.uploadType, this.indexer);
      } else {
        this.fileArray.splice(key, 1);

        if (scannedImg) {
          this.scannedImages.splice(key, 1);
        }
        this.$emit('setFiles', this.fileArray, this.uploadType, this.indexer);
      }
      this.showFiles = true;
    },
    toggleModal: function(url = '') {
      this.imgUrl = url;
      this.showModal = !this.showModal;
    },
  },
}
</script>

<style scoped>

</style>
