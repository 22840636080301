<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <SkeletonTable v-if="!loaded" />
    <div v-if="businesses.length > 0 && loaded">
      <div class="pb-4">
        <h3 class="text-2xl font-bold">Your Companies</h3>
      </div>

      <BusinessTables
          class="hidden md:block"
          :companyArray="businesses"
          :pagination="compPagination"
          :isLoadingNext="isLoadingNext"
          :isLoadingPrevious="isLoadingPrevious"
          @getCompanies="getAccountCompanies"
          @editBusiness="editBusiness" />
      <BusinessMobile
          class="md:hidden"
          :companyArray="businesses"
          :pagination="compPagination"
          :isLoadingNext="isLoadingNext"
          :isLoadingPrevious="isLoadingPrevious"
          @getCompanies="getAccountCompanies"
          @editBusiness="editBusiness" />

    </div>
    <div v-else-if="loaded" class="pb-4">
      <div class="text-center">
        <h3 class="mt-2 text-sm font-medium text-gray-900">No Companies Registered</h3>
        <p class="mt-1 text-sm text-gray-500">
          Get started by creating a new company.
        </p>
        <div class="mt-6">
          <button v-on:click="toggleAddBusinessModal" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            Add Company
          </button>
        </div>
      </div>
    </div>
    <AddBusiness v-if="showAddBusinessModal" @toggleModal="toggleAddBusinessModal"/>
    <EditBusiness
        v-if="showEditBusinessModal"
        :dto="business"
        :isEdit="true"
        @toggleModal="toggleEditBusinessModal"/>
  </div>
</template>

<script>
import BusinessService from "@/service/business";
import BusinessTables from '@/components/tables/Business';
import BusinessMobile from '@/components/tables/mobile/Business';
import AddBusiness from "@/components/modals/business/Form.business";
import EditBusiness from "@/components/modals/business/Form.business";
import SkeletonTable from '@/components/skeletons/Table';

export default {
  name: "Business",
  components: {
    BusinessTables,
    BusinessMobile,
    SkeletonTable,
    AddBusiness,
    EditBusiness,
  },
  data: () => ({
    businesses: [],
    business: {},
    showAddBusinessModal: false,
    showEditBusinessModal: false,
    compPage: 1,
    compPageSize: 25,
    compPagination: {
      show: 1,
      to: 25,
      totalItems: 0,
      totalPages: 1,
    },
    isLoadingNext: false,
    isLoadingPrevious: false,
    loaded: false
  }),
  created: async function() {
    this.$root.$on('BusinessCreated', async () => {
      await this.getAccountCompanies(this.compPage, this.compPageSize);
    });
  },
  beforeMount: async function() {
    await this.getAccountCompanies(this.compPage, this.compPageSize, false, false);
    this.loaded = true;
  },
  methods: {
    getAccountCompanies: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new BusinessService();
      await service.getAccountCompanies(page, pageSize)
        .then((response) => {
          const companies = response.data.items;

          if (companies.length === 1) {
            this.$router.push({name: 'BusinessShow', params: {id: companies[0].id, name: companies[0].name}});
          }

          for (const company of companies) {
            company.showFull = false;
          }
          this.businesses = [];
          this.businesses = companies;
          this.setCompanyPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        }).finally(() => {
          if (loadingNext) {
            this.isLoadingNext = !this.isLoadingNext;
          } else if (loadingPrevious) {
            this.isLoadingPrevious = !this.isLoadingPrevious;
          }
        });
    },
    editBusiness: async function(business) {
      await this.getCompany(business.id)
    },
    getCompany: async function(id) {
      const service = new BusinessService();
      await service.getCompanyById(id)
        .then((response) => {
          this.business = response.data;
          if (this.business.latitude && this.business.longitude)
            this.business.location = { lat: Number(this.business.latitude), lng: Number(this.business.longitude)  }

          this.toggleEditBusinessModal();
        }).catch((e) => {
          console.log(e.request);
        });
    },
    toggleEditBusinessModal: function() {
      this.showEditBusinessModal = !this.showEditBusinessModal
    },
    toggleAddBusinessModal: function() {
      this.showAddBusinessModal = !this.showAddBusinessModal;
    },
    setCompanyPagination: function(page, pageSize, totalItems) {
      this.compPage = page;
      this.compPageSize = pageSize;
      this.compPagination.totalItems = totalItems;

      this.compPagination.totalPages = Math.ceil(this.compPagination.totalItems / this.compPageSize);
    },
  }
}
</script>

<style scoped>

</style>
