var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-7xl mx-auto bg-white sm:px-6 relative"},[_vm._l((_vm.companies),function(company,key){return _c('div',{key:key,staticClass:"block"},[_c('div',{staticClass:"border-b"},[_c('div',{staticClass:"cursor-pointer hover:bg-gray-100 px-4 py-4",on:{"click":function($event){_vm.goToCompany(company.id, _vm.formatName(company.name))}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"pl-2"},[_c('span',[_vm._v(_vm._s(company.name))])])])])]),(company.showFull)?_c('div',{staticClass:"pt-2 cursor-pointer",on:{"click":function($event){company.showFull = !company.showFull}}},[(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Homepage';
}))?_c('router-link',{staticClass:"text-center text-blue-500 block mt-8",attrs:{"to":{name: 'BusinessPublicShow', params:{id: company.id, name: _vm.formatName(company.name)}}}},[_vm._v(" Show company ")]):(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Dashboard';
}) || _vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Business';
}))?_c('router-link',{staticClass:"text-center text-blue-500 block mt-8",attrs:{"to":{name: 'BusinessShow', params:{id: company.id, name: _vm.formatName(company.name)}}}},[_vm._v(" Show company ")]):_vm._e(),(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Business';
}))?_c('div',{staticClass:"mt-4"},[_c('span',{staticClass:"cursor-pointer text-center block text-blue-500 hover:text-blue-600",on:{"click":function($event){return _vm.editBusiness(company)}}},[_vm._v("Edit company")])]):_vm._e()],1):_vm._e()])])}),_c('Pagination',{attrs:{"pagination":_vm.pagination,"page":_vm.page,"isLoadingNext":_vm.isLoadingNext,"isLoadingPrevious":_vm.isLoadingPrevious},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }