<template>
<div id="index">
  <div v-if="!isAuthenticated">
    <Header />
    <router-view />
  </div>
  <div v-else
       v-bind:class="{'h-screen flex overflow-hidden bg-gray-100': $route.matched.some(record => record.meta.useTemplate)}">
    <SidebarMobile v-if="$route.matched.some(record => record.meta.useTemplate) && showSidebar"
                   @toggleSidebar="toggleSidebar"
                   class="fixed inset-0 flex z-40 lg:hidden" role="dialog" aria-modal="true" />
    <SidebarDesktop v-if="$route.matched.some(record => record.meta.useTemplate)"
                    class="hidden lg:flex lg:flex-shrink-0" />
    <div class="flex-1 overflow-auto focus:outline-none">
      <AuthHeader v-if="$route.matched.some(record => record.meta.useTemplate)"
              @toggleSidebar="toggleSidebar" class="relative flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none" />
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
        <PageHeader v-if="$route.matched.some(record => record.meta.useTemplate)" />
        <router-view @toggleSidebar="toggleSidebar"/>
      </main>
    </div>
  </div>
</div>
</template>

<script>
import SidebarMobile from "@/components/layouts/Sidebar.mobile";
import SidebarDesktop from "@/components/layouts/Sidebar.desktop";
import AuthHeader from "@/components/layouts/cp/Header";
import PageHeader from "@/components/layouts/cp/PageHeader";

import Header from "@/components/layouts/Header";
import store from "@/store";

export default {
  name: "Index",
  components: {
    Header,
    AuthHeader,
    PageHeader,
    SidebarMobile,
    SidebarDesktop,
  },
  data: () => ({
    isAuthenticated: store.getters.authenticated,
    showSidebar: false,
  }),
  beforeMount: function() {
    this.$root.$on('SetAuthentication', (bool) => {
      this.isAuthenticated = bool;
    });
  },
  methods: {
    toggleSidebar: function() {
      this.showSidebar = !this.showSidebar;
    }
  }
}
</script>

<style scoped>

</style>
