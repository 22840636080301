<template>
  <div class="block mt-2">
    <div v-if="accounts && accounts.length > 0" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Add to Account
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="max-w-lg mt-2 block">
          <select v-on:change="selectAccount($event)" v-model="accountId" class="w-full block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            <option selected :value="null">Select Account (optional)</option>
            <option v-for="(account, key) in accounts" :key="key" :value="account.id">{{account.username}}: {{account.email}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
      <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Select Chain
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div>
          <div class="relative">
            <button v-on:click="showChains = !showChains" type="button" class="relative max-w-lg bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
              <span v-if="tx.chainType === 0" class="flex items-center">
                <img src="@/assets/icons/btc.svg" class="w-6"/>
                <span class="ml-3 block truncate">Bitcoin</span>
              </span>
              <span v-if="tx.chainType === 1" class="flex items-center">
                <img src="@/assets/icons/eth.svg" class="w-6"/>
                <span class="ml-3 block truncate">Ethereum</span>
              </span>
              <span v-if="tx.chainType === 2" class="flex items-center">
                <img src="@/assets/icons/bnb.svg" class="w-6"/>
                <span class="ml-3 block truncate">Binance Smart Chain</span>
              </span>
              <span v-if="tx.chainType === 3" class="flex items-center">
                <img src="@/assets/icons/tron.svg" class="w-6"/>
                <span class="ml-3 block truncate">TRON</span>
              </span>
              <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <!-- Heroicon name: solid/selector -->
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </span>
            </button>
            <ul v-if="showChains" class="absolute z-10 mt-1 max-w-lg bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
              <li v-on:click="selectChainType(0)" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-2" role="option">
                <div class="flex items-center">
                  <img src="@/assets/icons/btc.svg" class="w-6"/>
                  <span class="font-normal ml-3 block truncate">Bitcoin</span>
                </div>
              </li>
              <li v-on:click="selectChainType(1)" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
                <div class="flex items-center">
                  <img src="@/assets/icons/eth.svg" class="w-6"/>
                  <span class="font-normal ml-3 block truncate">Ethereum</span>
                </div>
              </li>
              <li v-on:click="selectChainType(2)" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-1" role="option">
                <div class="flex items-center">
                  <img src="@/assets/icons/bnb.svg" class="w-6"/>
                  <span class="font-normal ml-3 block truncate">Binance Smart Chain</span>
                </div>
              </li>
              <li v-on:click="selectChainType(3)" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-1" role="option">
                <div class="flex items-center">
                  <img src="@/assets/icons/tron.svg" class="w-6"/>
                  <span class="font-normal ml-3 block truncate">TRON</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hideCompanies" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Company
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-3">
            <select required v-model="tx.companyId" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    <option selected disabled :value="null">Select Company</option>
                    <option v-for="(company, key) in companies" :key="key" :value="company.id">{{company.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Transaction Date
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-3">
            <datepicker
              @selected="formatDate"
              v-model="dateTime"
              wrapper-class="max-w-lg"
              placeholder="Date of Transaction"
              input-class="border-gray-300 text-sm w-full rounded-md focus:ring-indigo-500 shadow-sm focus:border-indigo-500"
              name="dateTime" />
          </div>
        </div>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Amount
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-3">
            <input
                type="number"
                v-model="tx.amount"
                required
                placeholder="Amount"
                class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
          <div>
            <select v-model="currency" class="select border-gray-300 rounded-md">
              <option class="text-gray-500 mt-4 sm:text-sm" :value="0">AED</option>
              <option class="text-gray-500 mt-4 sm:text-sm" :value="1">USD</option>
              <option class="text-gray-500 mt-4 sm:text-sm" :value="2">EUR</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Tx Hash
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="max-w-lg mt-2 block">
          <input
              type="text"
              required
              v-model="tx.txHash"
              placeholder="Tx hash"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
        </div>
      </div>
    </div>
    <div class="mt-8">
      <div v-if="!isEdit" class="text-center mx-auto">
        <button v-if="tx.txHash !== null && tx.amount !== null && !loading" v-on:click="createHash" class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Add Tx Hash</button>
        <button v-else-if="tx.txHash !== '' && loading" class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          Add Tx Hash
        </button>
        <button v-else class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
          Add Tx Hash
        </button>
      </div>
      <div v-else class="text-center mx-auto">
        <button v-if="tx.txHash !== null && tx.amount !== null && !loading" v-on:click="editHash" class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Edit Tx Hash</button>
        <button v-else-if="tx.txHash !== '' && loading" class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
          Edit Tx Hash
        </button>
        <button v-else class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
          Edit Tx Hash
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HashService from "../../../service/hash";
import Datepicker from "vuejs-datepicker";
import BusinessService from "@/service/business";


export default {
  name: "TxHashForm",
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    selectType: {
      type: Number,
      required: false,
      default: null
    },
    txFilled: {
      type: Object,
      required: false,
      default: null
    },
    accounts: {
      type: Array,
      required: false,
      default: null
    }
  },
  components: {
    Datepicker
  },
  data: () => ({
    accountId: null,
    dateTime: null,
    tx: {
      chainType: 0,
      txHash: null,
      amount: null,
      dateTime: null,
      companyId: null,
    },
    currency: 0, // 0 = AED, 1 = USD, 2 = EUR
    showChains: false,
    loading: false,
    companies: [], // list of companies,
    hideCompanies: false
  }),
  beforeMount: async function () {    
    await this.getAccountCompanies();      
  },
  mounted: function() {
    if (this.txFilled && this.isEdit) {
      this.tx = this.txFilled;

      console.log(this.tx);

      this.dateTime = this.txFilled.dateTime;

      this.currency = this.txFilled.currency
      if (this.tx.ownerAccountId) {
        this.accountId = this.tx.ownerAccountId;
      }
    }
  },
  methods: {
    createHash: async function() {
      this.loading = !this.loading;
      const service = new HashService();
      let companyId = this.tx.companyId;
      let tempOwnerAccountId = null;
      if(this.hideCompanies){
        let selectedCompanyId;
        this.$emit('getSelectedCompany', (x)=> selectedCompanyId=x);
        companyId = selectedCompanyId;

        let ownerAccountId;
        this.$emit('getOwnerAccountId', (x)=> ownerAccountId=x);
        tempOwnerAccountId = ownerAccountId;
      }      
      const dto = {
        TxHash: `${this.tx.txHash}`,
        ChainType: this.tx.chainType,
        Amount: this.tx.amount,
        Currency: this.currency, // USD
        DateTime: this.tx.dateTime,
        CompanyId: companyId
      }

      if (this.accountId) {
        dto.ownerAccountId = this.accountId;
      }
      if(tempOwnerAccountId)
      {
        dto.ownerAccountId = tempOwnerAccountId;
      }

      await service.add(dto)
        .then(async (response) => {
          this.$notifications(
            'TxHash Added',
            '',
            '',
            0,
            true
          );

          if (this.selectType && this.selectType === 4) {
            this.$emit('getCategories', response.data);
          }
        }).catch((e) => {
          const error = JSON.parse(e.request.response);
          this.$notifications(
            `Something Went Wrong Creating TxHash`,
            `${error.message}. Please try again`,
            '',
            1,
            true
          );
        }).finally(() => {
          this.tx = {
            chainType: 0,
            txHash: null,
            amount: null,
          };

          this.loading = !this.loading;
        });
    },
    editHash: async function() {
      this.loading = !this.loading;
      const service = new HashService();
      let companyId = this.tx.companyId;
      if(this.hideCompanies){
        let selectedCompanyId;
        this.$emit('getSelectedCompany', (x)=> selectedCompanyId=x);
        companyId = selectedCompanyId;
      }
      const dto = {
        Id: this.tx.id,
        TxHash: `${this.tx.txHash}`,
        ChainType: this.tx.chainType,
        Amount: this.tx.amount,
        Currency: this.currency,
        DateTime: this.tx.dateTime,
        CompanyId: companyId
      }

      await service.edit(dto)
        .then(async () => {
          this.loading = !this.loading;
          this.$notifications(
              'TxHash Edited',
              '',
              '',
              0,
              true
          );
        }).catch((e) => {
          console.log(e);
          console.log(e.request)
        });
    },
    formatDate: function(date) {
      this.tx.dateTime = date.toDateString();
    },
    selectAccount: function($event) {
      this.accountId = $event.target.value;
    },
    selectChainType: function(chainType) {
      this.tx.chainType = chainType;
      this.showChains = !this.showChains;
    },
    getAccountCompanies: async function () {
      let selectedCompanyId;
      this.$emit('getSelectedCompany', (x)=> selectedCompanyId=x);
      const service = new BusinessService();
      await service.getAccountEditCompanies(1, 1000)
        .then((response) => {
          if(selectedCompanyId != undefined)
          {
            this.hideCompanies = true;      
          }else
          {
            this.companies = response.data.items;          
          }
          
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
  }
}
</script>

<style scoped>

</style>
