<template>
 
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <h3>Company</h3>
    <select required v-model="company" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    <option selected :value="null">All</option>
                    <option v-for="(company, key) in companies" :key="key" :value="company.id">{{company.name}}</option>
    </select>
    <br/>
    <div class="max-w-7xl mx-auto mt-2 relative">
      <SkeletonCards v-if="!loaded" />
      <Stats v-if="loaded"/>
    </div>
    <br/>
    <div v-if="hashes && hashes.length > 0" class="block border bg-white rounded-2xl p-4">
      <div class="divide-y w-full">
        <div class="bg-gray-50 grid gap-3 grid-cols-8">
          <div class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Amount
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Chain
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Tx Hash
          </div>          
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Used In Receipts
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Company
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          </div>
          <div class="px-2 relative py-3">
            <select v-model="currency" class="block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option value="AED">AED</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
            </select>
          </div>
        </div>
        <div class="block">
          <div v-for="(tx, key) in hashes" :key="key">
            <div class="grid gap-3 grid-cols-8 bg-white cursor-pointer hover:bg-gray-50">
              <div class="md:px-2 pt-4 pb-2 whitespace-nowrap text-sm font-medium text-gray-800">
                <span v-if="currency === 'AED'">{{ tx.currencyValue.aed }}</span>
                <span v-if="currency === 'USD'">{{ tx.currencyValue.usd }}</span>
                <span v-if="currency === 'EUR'">{{ tx.currencyValue.eur }}</span>
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
                <div v-if="(tx.chainType === 0 || tx.chainType === 1 || tx.chainType === 2 || tx.chainType === 3)">
                  <span v-if="tx.chainType === 0">
                    <img src="@/assets/icons/btc.svg" class="w-10"/>
                  </span>
                  <span v-else-if="tx.chainType === 1">
                    <img src="@/assets/icons/eth.svg" class="w-10"/>
                  </span>
                  <span v-else-if="tx.chainType === 2">
                    <img src="@/assets/icons/bnb.svg" class="w-10"/>
                  </span>
                  <span v-else-if="tx.chainType === 3">
                    <img src="@/assets/icons/tron.svg" class="w-10"/>
                  </span>
                </div>
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
               <span v-if="Number(tx.chainType) === 0">
                    <a class="text-blue-500"
                       :href="`https://blockchain.com/btc/tx/${tx.txHash}`"
                       target="_blank"> {{ truncateString(tx.txHash, 8) }}</a>
                </span>
                <span v-if="Number(tx.chainType) === 1">
                    <a class="text-blue-500"
                       :href="`https://etherscan.io/tx/${tx.txHash}`"
                       target="_blank"> {{ truncateString(tx.txHash, 8) }}</a>
                </span>
                <span v-if="Number(tx.chainType) === 2">
                    <a class="text-blue-500"
                       :href="`https://bscscan.com/tx/${tx.txHash}`"
                       target="_blank"> {{ truncateString(tx.txHash, 8) }}</a>
                </span>
                <span v-if="Number(tx.chainType) === 3">
                  <a class="text-blue-500"
                      :href="`https://tronscan.org/#/transaction/${tx.txHash}`"
                      target="_blank"> {{ truncateString(tx.txHash, 8) }}</a>
                </span>
              </div>
              <div class="pt-4 pb-2 whitespace-nowrap text-sm font-medium text-gray-800">
                  <router-link :to="{name: 'TxReceipts', params: {txid: tx.id}}" class="text-blue-500 hover:text-blue-600">
                      {{ tx.totalReceipts }} (View Receipts)
                  </router-link>
              </div>
              <div class="pt-4 pb-2 truncate whitespace-nowrap text-sm font-medium text-gray-800">
                {{ formatDate(tx.dateTime) }}
              </div>
              <div class="pt-4 pb-2 truncate whitespace-nowrap text-sm font-medium text-gray-800">
                {{ tx.companyName }}
              </div>
              <div class="pt-4 pb-2 truncate whitespace-nowrap text-sm font-medium text-gray-800">
                {{ tx.ownerEmail }}
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="flex">
                  <div>
                    <router-link :to="{name: 'TxHashForm', params: {id: tx.id}}" class="text-blue-500 hover:text-blue-600">Edit</router-link>
                  </div>
                  <div class="pl-2">
                    <button v-on:click="remove(tx.id, tx.txHash)" class="bg-red-500 p-1 rounded-md hover:bg-red-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <h3 class="mt-2 text-sm font-medium text-gray-900">No Tx Hashes</h3>
    </div>
    <Usage v-if="showUsageModal" :dto="dto" :type="type" :usages="usages" @toggleModal="toggleModal" />
  </div>
</template>

<script>
import HashService from "@/service/hash";
import ReceiptService from "@/service/receipt";
import Usage from "@/components/modals/receipt/Usage";
import FormatHelper from "@/helpers/format";
import BusinessService from "@/service/business";
import Stats from '@/components/cards/TxHashStats.vue';
import SkeletonCards from '@/components/skeletons/Cards';

export default {
  name: "TxHash",
  components: {Usage, Stats, SkeletonCards},
  data: () => ({
    hash: '',
    hashes: [],
    loading: false,
    dto: {},
    usages: [],
    type: 2,
    currency: 'USD',
    showUsageModal: false,
    company: null,
    companies: [], // list of companies
    loaded: false    
  }),
  beforeMount: async function() {
    await this.initPage();
    this.loaded = true;    
  },
  methods: {
    initPage: async function() {
      await this.getAll();      
      await this.getAccountCompanies();
    },
    getAll: async function() {
      const service = new HashService();
      if(this.company != null)
      {
        await service.allCompany(this.company)
        .then((response) => {
          this.setCurrencyValues(response.data);   
        });
      }else{
        await service.all()
        .then((response) => {
          this.setCurrencyValues(response.data);
        });
      }      
    },
    setCurrencyValues: async function(txHashes) {  
      const helper = new FormatHelper();
      this.hashes = await helper.formatTxCurrencies(txHashes);
    },    
    truncateString: function(str, num) {
      if (str !== undefined) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
    remove: async function(id, dto) {
      // check if it is used in a receipt
      const service = new HashService();
      const receiptService = new ReceiptService();
      await receiptService.typeInReceipt(4, id)
          .then((response) => {
            console.log(response);
            if (response.data.length > 0) {
              this.usages = response.data;
              this.dto = dto;
              this.showUsageModal = true;
            } else {
              // delete
              service.delete(id)
                  .then(() => {
                    this.$notifications('Tx Hash Deleted', 'Successfully deleted the tx hash', '', 0, true);
                    this.initPage();
                  })
            }
          });
    },
    toggleModal: function() {
      this.showUsageModal = !this.showUsageModal;
    },
    formatDate: function(date) {
      return new Date(date)
          .toLocaleDateString(
              [],
              {
                day: 'numeric',
                year: 'numeric',
                month: 'short',
              });
    },
    getAccountCompanies: async function () {
      const service = new BusinessService();
      await service.getAccountEditCompanies(1, 1000)
        .then((response) => {
          this.companies = response.data.items;          
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
  },
  watch: {
    currency: {
      handler: function(val) {
        if (val === 'AED') {
          this.$root.$emit('setTxHashCurrency', 'AED');
        } else if (val === 'USD') {
          this.$root.$emit('setTxHashCurrency', 'USD');
        } else if (val === 'EUR') {
          this.$root.$emit('setTxHashCurrency', 'EUR');
        }
      },
      deep: true,
    },
    company: async function(val) {
      console.log(val);
      this.$root.$emit('setCompanyId', val);
      await this.initPage();
    }
  }
}
</script>

<style scoped>

</style>
