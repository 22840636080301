<template>
  <div>
    <div v-for="(receiptItem, i) in receiptItems" :key="i" class="bg-gray-50 px-2 space-y-6 sm:space-y-5">
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          <span>Receipt Items</span>
        </label>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5  sm:border-t sm:border-gray-200 ">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          <span>Description</span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <textarea v-model="receiptItem.description"
                    rows="3"
                    placeholder="Write a description about payment"
                    class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"></textarea>
        </div>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5  sm:border-t sm:border-gray-200">
        <div>
          <label :for="receiptItem.amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Amount paid
            <span class="text-red-500 pl-1">*</span>
          </label>
        </div>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="block text-sm text-gray-700">
            <div class="flex">
              <div>
                <label :for="receiptItem.amount">Amount</label>
                <input type="number" v-model="receiptItem.amount" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              </div>
              <div class="pl-3">
                <div class="block">
                  <label class="block">Currency</label>
                  <div class="select border-gray-300 rounded-md">
                    <span v-if="currency === 0" class="text-gray-500 mt-6 sm:text-sm">AED</span>
                    <span v-if="currency === 1" class="text-gray-500 mt-6 sm:text-sm">USD</span>
                    <span v-if="currency === 2" class="text-gray-500 mt-6 sm:text-sm">EUR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="receiptItem.type === 1" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5  sm:border-t sm:border-gray-200">
        <div>
          <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Valuation
          </label>
        </div>     

          <div v-if="receiptItem.previousValuation" class="col-start-2 col-end-2">
            <div>
              <label :for="receiptItem.valuation" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Previous Valuation
              </label>
            </div>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="block text-sm text-gray-700">
                <div class="flex">
                  <div>
                    <label :for="receiptItem.previousValuation">Amount</label>
                    <input type="number" v-model="receiptItem.previousValuation" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency" disabled>
                  </div>
                  <div class="pl-3">
                    <div class="block">
                      <label class="block">Currency</label>
                      <div class="select border-gray-300 rounded-md">
                        <span v-if="currency === 0" class="text-gray-500 mt-6 sm:text-sm">AED</span>
                        <span v-if="currency === 1" class="text-gray-500 mt-6 sm:text-sm">USD</span>
                        <span v-if="currency === 2" class="text-gray-500 mt-6 sm:text-sm">EUR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
               
          <div class="col-start-2 col-end-2">
            <div>
                <label :for="receiptItem.valuation" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Current Valuation
                </label>
              </div>
            <div class="mt-1 sm:mt-0 col-start-2">
              <div class="block text-sm text-gray-700">
                <div class="flex">
                  <div>
                    <label :for="receiptItem.valuation">Amount</label>
                    <input type="number" v-model="receiptItem.valuation" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                  </div>
                  <div class="pl-3">
                    <div class="block">
                      <label class="block">Currency</label>
                      <div class="select border-gray-300 rounded-md">
                        <span v-if="currency === 0" class="text-gray-500 mt-6 sm:text-sm">AED</span>
                        <span v-if="currency === 1" class="text-gray-500 mt-6 sm:text-sm">USD</span>
                        <span v-if="currency === 2" class="text-gray-500 mt-6 sm:text-sm">EUR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-start-2 col-end-2">
            <label :for="receiptItem.valuationDescription" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Description
                </label>
          </div>      
          <div class="col-start-2 col-end-2">
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea v-model="receiptItem.valuationDescription"
                        rows="3"
                        placeholder="Write a description about payment"
                        class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"></textarea>
            </div>
          </div>
          <div class="col-start-2 col-end-2">
            <ValuationFiles v-if="isEdit" label="Valuation Images" divId="valImg" :selectedFiles="receiptItem.valuationFiles" uploadTitle="Upload Photo(s) of Valuation(s)" :uploadType="4" :indexer="i" @setFiles="setValuationFiles" @deleteFile="deleteValuationFile"/>
            <ValuationFiles v-else label="Valuation Images" divId="valImg" uploadTitle="Upload Photo(s) of Valuation(s)" :uploadType="4" :indexer="i" @setFiles="setValuationFiles" @deleteFile="deleteValuationFile"/>
          </div>  
        

        
      </div>
      <div v-if="receiptItem.type === 0">
        <EmployeePayouts :indexer="i" :existingEmployeePayouts="receiptItem.employeePayouts" :isEdit="isEdit" @setEmployeePayouts="setEmployeePayouts" @deleteEmployeePayout="deleteEmployeePayout"/>
      </div>
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-5  sm:border-t sm:border-gray-200">
        <label :for="receiptType" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Expense or Asset
          <span class="text-red-500 pl-1">*</span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <select required v-model="receiptItem.type" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            <option selected disabled :value="null">Select Expense or Asset</option>
            <option v-for="(type, key) in receiptType" :key="key" :value="type.id">{{type.name}}</option>
          </select>
        </div>
      </div>
      <CategorySearch label="Expense Category" :required="true" :records="categories" :selectedCategory="receiptItem.expenseCategory" :selectType="2" :indexer="i" @setCategory="setCategory" @getCategory="getCategory" @getOwnerAccountId="getOwnerAccountId"/>
      <CategorySearch label="Expense" :required="true" :records="expenses" :selectedCategory="receiptItem.expense" :selectType="3" :indexer="i" @setCategory="setCategory" @getCategory="getCategory" @getOwnerAccountId="getOwnerAccountId"/>

      <Files v-if="isEdit" label="Product Images" divId="prodImg" :selectedFiles="receiptItem.productImages" uploadTitle="Upload Photo(s) of Product(s)" :uploadType="4" :indexer="i" @setFiles="setFiles" @deleteFile="deleteFile"/>
      <Files v-else label="Product Images" divId="prodImg" uploadTitle="Upload Photo(s) of Product(s)" :uploadType="4" :indexer="i" @setFiles="setFiles" @deleteFile="deleteFile"/>
      <Files v-if="isEdit" label="Product Videos" divId="prodVid" :selectedFiles="receiptItem.productVideos" uploadTitle="Upload Video(s) of Product(s)" :uploadType="5" :indexer="i" @setFiles="setFiles" @deleteFile="deleteFile"/>
      <Files v-else label="Product Videos" divId="prodVid" uploadTitle="Upload Video(s) of Product(s)" :uploadType="5" :indexer="i" @setFiles="setFiles" @deleteFile="deleteFile"/>

      <CustomFields :indexer="i" :existingFields="receiptItem.customFields" @setCustomFields="setCustomFields" @deleteCustomField="deleteCustomField"/>

      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5  sm:border-t sm:border-gray-200">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"></label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="max-w-lg pt-5 text-center">
            <button v-on:click="deleteReceiptItem(i)" class="bg-red-500 hover:bg-red-600 text-white flex rounded mx-auto block px-2 mt-2 py-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
              <span class="pl-2">Delete Item</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"></label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="max-w-lg pt-5 text-center">
          <button v-on:click="addReceiptItem" class="bg-blue-500 mx-auto cursor-pointer hover:bg-blue-600 px-3 py-2 rounded-lg flex text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white " fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="pl-2">Add Receipt Item</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySearch from './CategorySearch.vue'
import Files from './Files.vue'
import ValuationFiles from './ValuationFiles.vue'
import CustomFields from "@/components/form/receipt/CustomFields";
import EmployeePayouts from "@/components/form/receipt/EmployeePayouts";

export default {
  name: "Items",
  components: {
    CustomFields,
    CategorySearch,
    Files,
    ValuationFiles,
    EmployeePayouts
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    expenses: {
      type: Array,
      required: true
    },
    currency: {
      type: Number,
      required: true
    },
    existingItems: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    receiptItems: [],
    receiptType: [
      {
        id: 0,
        name: 'Expense'
      },
      {
        id: 1,
        name: 'Asset'
      },
      {
        id: 2,
        name: 'Advanced Payment'
      }
    ],
    itemSet: false,
  }),
  beforeMount: function() {
    if (this.existingItems && this.existingItems.length > 0) {
      this.existingItems.forEach(item => {
        const receiptItem = {
          id: item.id,
          description: item.description,
          amount: item.amount,
          valuation: item.valuation,
          valuationDescription: item.valuationDescription,
          previousValuation: item.previousValuation,
          valuationFiles: item.valuationFiles,
          valuationDifferencePercentage : item.valuationDifferencePercentage,
          expense: {
            id: item.expenseHeadId,
            name: item.expenseHeadName,
          },
          expenseCategory: {
            id: item.expenseCategoryId,
            name: item.expenseCategoryName,
          },
          expenseCategoryId: item.expenseCategories,
          expenses: item.expenses,
          type: item.type,
          productImages: item.productImages,
          productVideos: item.productVideos,
          customFields: item.customFields,
          employeePayouts: item.employeePayouts,
          productImagesNew: [],
          productVideosNew: [],
          customFieldsNew: [],
          valuationFilesNew: [],
          employeePayoutsNew: []
        }
        this.receiptItems.push(receiptItem)
      });
    }
  },
  methods: {
    getCategory: function(type){
      this.$emit('getCategory', type);
    },
    getOwnerAccountId: function(callback){
      let res;
      this.$emit('getOwnerAccountId', (x)=> res=x);
      callback(res);
    },
    setCategory: function(category, type, indexer) {
      this.itemSet = false;
      // Expense Category
      if (type === 2) {
        this.receiptItems[indexer].expenseCategoryId = category.id;
      } else if (type === 3) { // Expense
        this.receiptItems[indexer].expenseId = category.id;
      }
    },
    setFiles: function(files, type, indexer) {
      this.itemSet = false;

      if (type === 4) {
        if (!this.isEdit) {
          this.receiptItems[indexer].productImages = files;
        } else {
          this.receiptItems[indexer].productImagesNew = files;
        }
      } else if (type === 5) {
        if (!this.isEdit) {
          this.receiptItems[indexer].productVideos = files;
        } else {
          this.receiptItems[indexer].productVideosNew = files;
        }
      }
    },
    setValuationFiles: function(files, type, indexer) {
      this.itemSet = false;

      if (type === 4) {
        if (!this.isEdit) {
          this.receiptItems[indexer].valuationFiles = files;
        } else {
          this.receiptItems[indexer].valuationFilesNew = files;
        }
      }
    },
    setCustomFields: function(customFields, indexer) {
      this.itemSet = false;
      if (!this.isEdit) {
        this.receiptItems[indexer].customFields = customFields;
      } else {
        this.receiptItems[indexer].customFieldsNew = customFields;
      }
    },
    setEmployeePayouts: function(employeePayouts, indexer){
      this.itemSet = false;
      if (!this.isEdit) {
        this.receiptItems[indexer].employeePayouts = employeePayouts;
      } else {
        this.receiptItems[indexer].employeePayoutsNew = employeePayouts;
      }
    },
    addReceiptItem: function() {
      const item = {
        description: null,
        amount: null,
        valuation: null,
        valuationDescription: null,
        valuationFiles: [],
        type: 0, // expense or asset
        expenseCategoryId: null,
        currency: 0,
        selectedExpenseCategory: {
          name: null,
          id: null
        },
        expenseId: null,
        selectedExpense: {
          name: null,
          id: null
        },
        productImages: [],
        productVideos: [],
        customFields: [],
        employeePayouts: [],
      }
      this.receiptItems.push(item);
      this.itemSet = false;
    },
    deleteCustomField: function(key, indexer) {
      this.receiptItems[indexer].customFields.splice(key, 1);
    },
    deleteEmployeePayout: function(key, indexer) {
      this.receiptItems[indexer].employeePayouts.splice(key, 1);
    },
    deleteFile: function(key, uploadType, indexer) {
      if (uploadType === 4) {
        this.receiptItems[indexer].productImages.splice(key, 1);
      } else if (uploadType === 5) {
        this.receiptItems[indexer].productVideos.splice(key, 1);
      }
    },
    deleteValuationFile: function(key, uploadType, indexer) {
      if (uploadType === 4) {
        this.receiptItems[indexer].valuationFiles.splice(key, 1);
      }
    },
    deleteReceiptItem: function(key) {
      this.itemSet = false;
      this.receiptItems.splice(key, 1);
    },
  },
  watch: {
    receiptItems:  {
      handler: function(items) {        
        if (items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if(item.type === 0)//reset valuation on type switch
            {
              item.valuation = null;
            }              
          }
        }
        this.$emit('setItems', this.receiptItems);
      },
      deep: true
    },
  },
}
</script>

<style scoped>

</style>
