<template>
  <div>
    <div class="credentials animate-pulse">
      <div class="header">
        <h2 class="text-3xl font-bold mt-2">
          <div class="w-24 bg-gray-300 h-8 mt-2 rounded-md"></div>
        </h2>
        <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
        <div class="w-48 bg-gray-300 h-4 mt-2 rounded-md "></div>
      </div>
      <div class="body mt-8">
        <div class="w-2/3">
          <div class="block">
            <div class="w-full bg-gray-300 h-12 mt-2 rounded-md "></div>
          </div>
          <div class="block mt-6">
            <div class="w-full bg-gray-300 h-12 mt-2 rounded-md "></div>
          </div>
          <div class="block mt-6">
            <div class="w-full bg-gray-300 h-12 mt-2 rounded-md "></div>
          </div>
          <div class="block mt-6">
          </div>
          <div  class="block my-2 px-3 py-2 rounded-md">
            <div class="w-full bg-gray-300 h-12 mt-2 rounded-md "></div>
          </div>
          <div class="flex mt-6">
            <div class="w-12 bg-gray-300 h-4 mt-2 rounded-md "></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile"
}
</script>

<style scoped>

</style>
