import axios from "axios";
import store from "@/store";

export default class StatsService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
        }
    }

    async getGlobalStats() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/stats/global`);
    }

    async getAccountStats() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/stats/account`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getCompanyStats(id: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/stats/company/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getTxHashStats(id: number) {
        if(id === null)
        {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/stats/txhashes/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
        return await axios.get(`${process.env.VUE_APP_SERVICE}/stats/txhashes?companyId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
