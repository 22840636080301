import store from '@/store';

let vm = {}
export default {
    install(Vue, opts) {
        opts = {
            conversion: 0
        };
        if (store.getters.conversion.aed.eur === 0 && store.getters.conversion.aed.usd === 0) {
            fetch(`https://v6.exchangerate-api.com/v6/4c91fdc163674f4510a41db4/latest/AED`)
                .then(response => response.json())
                .then(response => {
                    store.state.conversion.aed.eur = response.conversion_rates.EUR
                    store.state.conversion.aed.usd = response.conversion_rates.USD
                });

            // store.state.conversion.aed.eur = 1;
            // store.state.conversion.aed.usd = 1;
        }
        if (store.getters.conversion.usd.eur === 0 && store.getters.conversion.usd.aed === 0) {
            fetch(`https://v6.exchangerate-api.com/v6/4c91fdc163674f4510a41db4/latest/USD`)
                .then(response => response.json())
                .then(response => {
                    store.state.conversion.usd.aed = response.conversion_rates.AED
                    store.state.conversion.usd.eur = response.conversion_rates.EUR
                });

            // store.state.conversion.usd.aed = 1;
            // store.state.conversion.usd.eur = 1;
        }
        if (store.getters.conversion.eur.aed === 0 && store.getters.conversion.eur.usd === 0) {
            fetch(`https://v6.exchangerate-api.com/v6/4c91fdc163674f4510a41db4/latest/EUR`)
                .then(response => response.json())
                .then(response => {
                    store.state.conversion.eur.aed = response.conversion_rates.AED
                    store.state.conversion.eur.usd = response.conversion_rates.USD
                });

            // store.state.conversion.eur.aed = 1;
            // store.state.conversion.eur.usd = 1;
        }
    },
}
