<template>
    <div :class="'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'">
      <div>
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ label }}
        </label> 
      </div>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div v-if="selectedFiles && selectedFiles.length > 0" class="inline-block min-w-full">
          <div v-if="showFiles" class="max-w-lg mb-4 flex overflow-x-auto">
            <div v-for="(file, key) in selectedFiles" :key="key">
              <div v-if="file !== null" class="w-56 mr-5 rounded-md">
                <input type="text" v-model="file.name" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="Name">
                <img :src="file.url" v-on:click="toggleModal(file.url)" class="w-full h-36 rounded-md cursor-pointer">
                <button v-on:click="deleteFile(key, true)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">         
          <div v-if="!imgLoading" class="space-y-1 text-center">
            <svg v-if="fileArray.length === 0" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
            </svg>
            <div class="flex text-sm text-gray-600">
              <label v-if="fileArray.length === 0" :for="divId" class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-600">
                <span>{{uploadTitle}}</span>
                <input v-on:change="selectFile"  accept="image/*" :id="divId" multiple type="file" class="sr-only">  
              </label>
              <div class="block">                
                <div>
                  <div v-for="(file, key) in fileArray" :key="key" class="text-blue-600 font-medium block">
                    <div class="block">
                      <input type="text" v-model="file.NewName" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="Name">
                      {{file.name}}                      
                      <img v-if="file.name !== ''" :id="`category-valImg-${key}`"/>                      
                      <button v-if="file.name !== ''" v-on:click="deleteFile(key)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal"/>
    </div>
  </template>
  
  <script>
  import ReceiptService from "@/service/receipt";
  import ImgViewer from '@/components/modals/receipt/ImageViewer';
  
  export default {
    name: "ReceiptFiles",
    components: {
      ImgViewer
    },
    props: {
      label: {
        type: String,
        required: true
      },
      uploadTitle: {
        type: String,
        required: true,
      },
      uploadType: {
        type: Number,
        required: true,
      },
      divId: {
        type: String,
        required: true,
      },
      indexer: {
        type: Number,
        required: false,
        default: null
      },
      selectedFiles: {
        type: Array,
        required: false,
        default: null
      },
    },
    data: () => ({
      showFiles: false,
      fileArray: [],
      useScanner: false,
      scannedImages: [],
      imgLoading: false,
      hasScannedReceipt: false,
      imgUrl: null,
      showModal: false,
      isImgType: false, // when business license is img instead of pdf
    }),
    created: function() {
      this.showFiles = true;
    },
    methods: {
      selectFile: async function(event) {
        this.fileArray = [];
        if (this.useScanner) {
          this.imgLoading = !this.imgLoading;
          this.scannedImages = [];
          const files = event.target.files;
          for(let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('file', files[i], files[i].name);
  
            const service = new ReceiptService();
            await service.getImageReceipt(formData)
              .then(response => {
                this.hasScannedReceipt = true;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.scannedImages.push(url);
  
                let blob = new Blob([response.data]);
                let file = this.blobToFile(blob, files[i].name);
                this.fileArray.push(file);
              }).catch((e) => {
                console.log(e.request);
              });
          }
  
          this.imgLoading = !this.imgLoading;
          this.appendFiles();
        } else {
          const files = event.target.files;
          let uploadType = this.uploadType;
          for (let i = 0; i < files.length; i++) {
            this.fileArray.push(files[i]);
  
            if (uploadType !== 3) {
              let reader = new FileReader();
              reader.onload = function (e) {         
                  document.getElementById(`category-valImg-${i}`).setAttribute('src', e.target.result);                
              }
              reader.readAsDataURL(files[i]);
            }
          }
        }
  
        this.$emit('setFiles', this.fileArray, this.uploadType, this.indexer);
      },
      appendFiles: function() {
        for(let i = 0; i < this.scannedImages.length; i++) {
          document.getElementById(`scannedImg-${i}`).setAttribute('src', this.scannedImages[i]);
        }
      },
      deleteFile: async function(key, existingFiles = false, scannedImg = false) {
        if (existingFiles) {
          this.showFiles = false;
          await this.$emit('deleteFile', key, this.uploadType, this.indexer);
        } else {
          this.fileArray.splice(key, 1);
  
          if (scannedImg) {
            this.scannedImages.splice(key, 1);
          }
          this.$emit('setFiles', this.fileArray, this.uploadType, this.indexer);
        }
        this.showFiles = true;
      },
      toggleModal: function(url = '') {
        this.imgUrl = url;
        this.showModal = !this.showModal;
      },
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  