<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Receipt
              </h3>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                    <p>Visibility</p>
                    <p class="text-gray-500 text-xs pt-1">Do you want this transaction to be public or private?</p>
                  </div>
                </div>
                <div class="mt-4 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg space-y-4">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input type="checkbox"
                               v-model="isPublic"
                               class="focus:ring-indigo-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
                      </div>
                      <div class="ml-3 text-sm">
                        <label class="font-medium text-gray-700">Public</label>
                        <p class="text-gray-500">By selecting this option your information will be publicly visible.</p>
                      </div>
                    </div>
                  </div>
                  <div class="max-w-lg space-y-4 mt-2">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input v-model="isPrivate"
                               type="checkbox"
                               class="focus:ring-indigo-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
                      </div>
                      <div class="ml-3 text-sm">
                        <label :for="isPrivate" class="font-medium text-gray-700">Private</label>
                        <p class="text-gray-500">By selecting this option your information will be displayed privately and is not publicly visible.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label :for="companies" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Select Your Company
                  <span class="text-red-500 pl-1">*</span>
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select required v-on:change="setCompany($event)" v-model="receipt.companyId" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    <option selected disabled :value="null">Select Company</option>
                    <option v-for="(company, key) in companies" :key="key" :value="company.id">{{company.name}}</option>
                  </select>
                </div>
              </div>
              <div v-if="receipt.companyId !== null" class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label :for="receipt.createdDate" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Date of Receipt
                    <span class="text-red-500 pl-1">*</span>
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <datepicker
                        @selected="formatDate"
                        v-model="receiptDate"
                        wrapper-class="max-w-lg"
                        placeholder="Date of Receipt"
                        input-class="border-gray-300 text-sm w-full rounded-md focus:ring-indigo-500 shadow-sm focus:border-indigo-500"
                        name="Date of receipt" />
                  </div>
                </div>
                <CategorySearch label="Vendor" :records="vendors" :selectedCategory="receipt.vendor" :required="true" :selectType="1" @setCategory="setCategory" @getCategory="getCategory" @resetCategory="resetCategory" @getOwnerAccountId="getOwnerAccountId"/>
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label :for="receipt.amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Amount paid
                      <span class="text-red-500 pl-1">*</span>
                    </label>
                  </div>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <div class="block text-sm text-gray-700">
                      <div class="flex">
                        <div>
                          <label :for="receipt.amount">Amount</label>
                          <input type="text" v-model="receipt.amount" class="focus:ring-blue-500 max-w-lg z-0 mt-1 focus:border-blue-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                        </div>
                        <div class="pl-3">
                          <div class="block">
                            <label class="block">Currency</label>
                            <select v-model="currency" class="select border-gray-300 rounded-md">
                              <option class="text-gray-500 mt-4 sm:text-sm" :value="0">AED</option>
                              <option class="text-gray-500 mt-4 sm:text-sm" :value="1">USD</option>
                              <option class="text-gray-500 mt-4 sm:text-sm" :value="2">EUR</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PaymentMethod label="Payment Method" :selectType="1" :selectedPayment="receipt.payment" @setPayment="setPayment"/>
                <CategorySearch label="Tx Hash" :records="txHashes" :selectedCategory="receipt.txHash" :selectType="4" @setCategory="setCategory" @getCategory="getCategory" @resetCategory="resetCategory"  @getSelectedCompany="getSelectedCompany" @getOwnerAccountId="getOwnerAccountId"/>

                <Files v-if="isEdit" label="Invoice" divId="popImg" :selectedFiles="receipt.proofOfPayments" uploadTitle="Upload Invoice" :uploadType="2" @setFiles="setFiles" @deleteFile="deleteFile"/>
                <Files v-else label="Invoice" divId="popImg" uploadTitle="Upload Invoice" :uploadType="2" @setFiles="setFiles" @deleteFile="deleteFile"/>

                <Files v-if="isEdit" label="Receipt / Proof of Payment" divId="receiptImg" :selectedFiles="receipt.receiptImages" uploadTitle="Upload Photo of Receipt / Proof of Payment" :uploadType="1" @setFiles="setFiles" @deleteFile="deleteFile"/>
                <Files v-else label="Receipt / Proof of Payment" divId="receiptImg" uploadTitle="Upload Photo of Receipt / Proof of Payment" :uploadType="1" @setFiles="setFiles" @deleteFile="deleteFile"/>                

                <Files v-if="isEdit" label="Receipt Documents" divId="receiptDoc" :selectedFiles="receipt.receiptDocuments" uploadTitle="Upload Receipt Documents (.pdf)" :uploadType="3" @setFiles="setFiles" @deleteFile="deleteFile"/>
                <Files v-else label="Receipt Documents" divId="receiptDoc" uploadTitle="Upload Receipt Documents (.pdf)" :uploadType="3" @setFiles="setFiles" @deleteFile="deleteFile"/>

                <Items :categories="expenseCategories" :existingItems="receipt.receiptItems" :expenses="expenses" :currency="currency" :isEdit="isEdit" @setItems="setItems" @getCategory="getCategory" @getOwnerAccountId="getOwnerAccountId"/>
                <CustomFields :companyId="Number(receipt.companyId)" :existingFields="receipt.customFields" :isEdit="isEdit" @setCustomFields="setCustomFields" @deleteCustomField="deleteCustomField"/>
              </div>
            </div>
            <div class="pt-5 flex w-full">
              <div class="hidden md:flex md:flex-grow">
                <div class="block md:flex">
                  <div class="block md:flex">
                    <div >
                      <button v-on:click="resetPage" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-800 hover:text-white focus:outline-none">
                        Clear Receipt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isEdit" class="block md:flex">
                <div class="block md:flex">
                  <div class="block md:flex">
                    <div v-if="isFormValid">
                      <button v-if="!loading"
                              v-on:click="addReceipt(false)" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Save
                      </button>
                      <button v-else-if="loading" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </span>
                        Save
                      </button>
                    </div>
                    <button v-else class="group cursor-not-allowed opacity-70 relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 focus:outline-none ">
                      Save
                    </button>
                  </div>
                  <div class="block md:flex md:pl-4 mt-2 md:mt-0">
                    <div v-if="isFormValid">
                      <button v-if="!loadingNew"
                              v-on:click="addReceipt(true)" class="group relative w-full md:w-56 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Save &amp; add another
                      </button>
                      <button v-else-if="loadingNew" class="group relative w-full md:w-56 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </span>
                        Save &amp; add another
                      </button>
                    </div>
                    <button v-else class="group cursor-not-allowed opacity-70 relative w-full md:w-56 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 focus:outline-none ">
                      Save &amp; add another
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="pt-5">
                <div class="flex justify-end">
                  <div v-if="isFormValid">
                    <button v-if="!loading"
                            v-on:click="editReceipt" class="group relative w-40 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Save Changes
                    </button>
                    <button v-else-if="loading" class="group relative w-40 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                      Save Changes
                    </button>
                  </div>
                  <button v-else class="group cursor-not-allowed opacity-70 relative w-40 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 focus:outline-none ">
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessService from "@/service/business";
import VendorService from "@/service/vendor";
import ExpenseService from "@/service/expense";
import HashService from "@/service/hash";
import ReceiptService from "@/service/receipt";
import Datepicker from "vuejs-datepicker";
import CategorySearch from "@/components/form/receipt/CategorySearch";
import Files from "@/components/form/receipt/Files";
import Items from "@/components/form/receipt/Items";
import CustomFields from "@/components/form/receipt/CustomFields";
import PaymentMethod from "@/components/form/receipt/Payment";
import FormatHelper from "@/helpers/format";

export default {
  name: "FormReceipt",
  components: {
    PaymentMethod,
    CustomFields,
    Items,
    CategorySearch,
    Datepicker,
    Files
  },
  data: () => ({
    id: null,
    isPublic: true,
    isPrivate: false,
    receipt: {
      id: null,
      isPrivate: false,
      companyId: null,
      createdDate: null,
      vendorId: null,
      txHashId: null,
      txHash: null,
      receiptImages: [],
      proofOfPayments: [],
      receiptDocuments: [],
      receiptItems: [],
      amount: null,
      payment: {
        method: null, // 0 = Cash, 1 = Credit Card, 2 = Crypto, 3 = Cheque, 4 = Wire Transfer
        chainType: null, // 0 = BTC, 1 = ETH, 2 = BNB
        txHashId: null,
        reference: null,
      },
      customFields: [],
    },
    receiptDate: null,
    currency: 0, // 0 = AED, 1 = USD, 2 = EUR
    companies: [], // list of companies
    vendors: [], // list of vendors
    expenseCategories: [], // list of expense categories
    expenses: [], // list of expenses
    txHashes: [], // list of tx hashes,
    loading: false, // loading state create receipt
    loadingNew: false, // loading state create receipt and stay on page
    isFormValid: false, // form validation
    isEdit: false,
    txFilters: {
      companyId: null,
      amount: null,
      createdDate: null,
      currency: null
    },
    ownerAccountId: null
  }),
  beforeMount: async function () {
    if (this.$route.name === 'ReceiptEdit') {
      this.isEdit = true;
    }

    if (this.isEdit) {
      this.id = this.$route.params.id;
      await this.getReceipt();
    }
    await this.getAccountCompanies();
  },
  methods: {
    setOwnerAccountId: async function(companyId){
      const service = new BusinessService();
      await service.getOwnerIdWrite(companyId)
        .then((response) =>{
          this.ownerAccountId = response.data;
        });
    },
    getReceipt: async function() {
      const service = new ReceiptService();
      await service.getReceiptById(this.id)
        .then((response) => {
          const receipt = {
            id: response.data.id,
            slNo: response.data.slNo,
            isPrivate: response.data.isPrivate,
            companyId: response.data.companyId,
            createdDate: response.data.created,
            vendor: {
              id: response.data.vendorId,
              name: response.data.vendorName,
            },
            txHash: {
              id: response.data.txHashId,
              txHash: response.data.txHash,
            },
            receiptImages: response.data.receiptImages,
            proofOfPayments: response.data.proofOfPaymentImages,
            receiptDocuments: response.data.receiptDocuments,
            receiptItems: response.data.items,
            receiptImagesNew: [],
            proofOfPaymentsNew: [],
            receiptDocumentsNew: [],
            amount: response.data.amount,
            payment: {
              method: response.data.paymentMethod, // 0 = Cash, 1 = Credit Card, 2 = Cheque, 3 = Wire Transfer
              reference: response.data.paymentReference,
            },
            customFields: response.data.customFields,
            customFieldsNew: [],
            currency: response.data.currency,
            isOwner: response.data.isOwner,
          }
          this.currency = response.data.currency;

          if (receipt.isPrivate) {
            this.isPublic = false;
            this.isPrivate = true;
          } else {
            this.isPublic = true;
            this.isPrivate = false;
          }
          this.receipt = receipt;

          this.receiptDate = this.receipt.createdDate;
          this.setOwnerAccountId(this.receipt.companyId);
          this.setCompany(this.receipt.companyId);

          this.txFilters = {
            companyId: receipt.companyId,
            amount: receipt.amount,
            createdDate: receipt.createdDate,
            currency: this.currency
          };
      });
    },
    addReceipt: async function(createNew) {
      if (createNew) {
        this.loadingNew = !this.loadingNew;
      } else {
        this.loading = !this.loading;
      }

      const service = new ReceiptService();
      await service.addReceipt(this.receipt, this.currency)
        .then((response) => {
          this.$notifications(
              'Receipt Added',
              response.data.message,
              '',
              0,
              true
          );
          if (!createNew)
            this.$router.push({name: 'ReceiptShow', params: {id: response.data.id}});
          this.resetPage();
        }).catch((e) => {
          if (createNew) {
            this.loadingNew = !this.loadingNew;
          } else {
            this.loading = !this.loading;
          }
          if (e.request && e.request.response) {
            const error = JSON.parse(e.request.response);
            this.$notifications(
                `Something Went Wrong Adding The Receipt`,
                `${error.message}. Please try again`,
                '',
                1,
                true
            );
          } else {
            this.$notifications(
                'Internal Server Error',
                `Something went wrong adding the receipt. Status code: 500`,
                '',
                1,
                true
            );
          }
        });
    },
    editReceipt: async function() {
      this.loading = !this.loading;

      const service = new ReceiptService();
      // Receipt always posted in USD now
      await service.updateReceipt(this.receipt, this.currency)
        .then((response) => {
          this.$notifications(
              'Receipt Edited',
              response.data.message,
              '',
              0,
              true
          );
          this.$router.push({name: 'ReceiptShow', params: {id: this.id}});
        }).catch((e) => {
          this.loading = !this.loading;
          if (e.request && e.request.response) {
            const error = JSON.parse(e.request.response);
            this.$notifications(
                `Something Went Wrong Editing The Receipt`,
                `${error.message}. Please try again`,
                '',
                1,
                true
            );
          } else {
            console.log(e)
            this.$notifications(
                'Internal Server Error',
                `Something went wrong editing the receipt. Status code: 500`,
                '',
                1,
                true
            );
          }
        });
    },
    setCompany: function(event) {
      if (event !== Number(event)) {             
        this.receipt.companyId = event.target.value;
        this.setOwnerAccountId(event.target.value);
      }
      this.getCategories()
    },
    setCategory: function(category, type) {
      if (type === 1) {
        this.receipt.vendorId = category.id;
      } else if (type === 4) {
        this.receipt.txHashId = category.id;
      }
    },
    setFiles: function(files, type) {
      if (type === 1) {
        if (!this.isEdit) {
          this.receipt.receiptImages = files;
        } else {
          this.receipt.receiptImagesNew = files;
        }
      } else if (type === 2) {
        if (!this.isEdit) {
          this.receipt.proofOfPayments = files;
        } else {
          this.receipt.proofOfPaymentsNew = files;
        }
      } else if (type === 3) {
        if (!this.isEdit) {
          this.receipt.receiptDocuments = files;
        } else {
          this.receipt.receiptDocumentsNew = files;
        }
      }
    },
    setItems: function(items) {
      this.receipt.receiptItems = items;
    },
    setPayment: function(payment) {
      this.receipt.payment = payment;
    },
    setCustomFields: function(customFields) {
      if (!this.isEdit) {
        this.receipt.customFields = customFields;
      } else {
        this.receipt.customFieldsNew = customFields;
      }
    },
    getAccountCompanies: async function () {
      const service = new BusinessService();
      await service.getAccountEditCompanies(1, 1000)
        .then((response) => {
          this.companies = response.data.items;
          if (!this.isEdit) {
            if (this.companies.length > 0 && this.companies.length < 2) {
              this.receipt.companyId = this.companies[0].id;
              this.setOwnerAccountId(this.companies[0].id);
              this.setCompany(this.receipt.companyId);
            }
          }
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
    getCategory: function(type) {
      if (type === 4) {
        this.getTxHashes(); // 4
      }
      if(type === 2){
        this.getExpensesCategories();
      }
      if(type === 3)
      {
        this.getExpenses();
      }
      if(type === 1)
      {
        this.getVendors();
      }
    },
    getSelectedCompany: function(callback){
      callback(this.receipt.companyId);
    },
    getOwnerAccountId: function(callback){
      callback(this.ownerAccountId);
    },
    getCategories: function() {
      this.getVendors(); // 1
      this.getExpensesCategories(); // 2
      this.getExpenses(); // 3
    },
    getFilteredTxHashes: function() {
      this.getTxHashes(); // 4
    },
    getVendors: function() {
      const service = new VendorService();
      service.all(this.receipt.companyId)
        .then((response) => {
          this.vendors = response.data.items;
        });
    },
    getExpensesCategories: function() {
      const service = new ExpenseService();
      service.allCategories(this.receipt.companyId)
        .then((response) => {
          this.expenseCategories = response.data.items;
        });
    },
    getExpenses: function() {
      const service = new ExpenseService();
      service.allHeads(this.receipt.companyId)
        .then((response) => {
          this.expenses = response.data.items;
        });
    },
    getTxHashes: async function() {
      const service = new HashService();
      let receiptId = this.isEdit ? this.receipt.id : null;
      console.log(receiptId);
      await service.allSuggestions(this.txFilters.companyId, this.txFilters.amount, this.txFilters.currency, this.txFilters.createdDate, receiptId)
        .then((response) => {
          if (response.data.message && response.data.message === 'No Hashes Found') {
            // this.getAllTxHashes();
            this.receipt.txHashId = null;
            this.receipt.txHash = {
              id: null,
              txHash: null
            };
            this.txHashes = [];
          } else {   
            this.receipt.txHashId = response.data[0].id;
            this.receipt.txHash = response.data[0];                        
            this.txHashes = response.data;
          }
        });
      await this.formatTxCurrency(false);
    },
    getAllTxHashes: async function() {
      const service = new HashService();
      await service.all(this.receipt.companyId, 0, null, '')
          .then((response) => {
            this.txHashes = response.data;
          });
      await this.formatTxCurrency(true);
    },
    formatTxCurrency: async function(bool) {
      const helper = new FormatHelper();
      for (let i = 0; i < this.txHashes.length; i++) {
        const hash = this.txHashes[i];
        if (hash.currency === 0) {
          // tx is added in AED
          hash.amount = await helper.formatFromAed(hash.amount, 1);
          hash.amountLeft = await helper.formatFromAed(hash.amountLeft, 1);
        } else if (hash.currency === 1) {
          // tx is added in USD
          hash.amount = await helper.formatFromDollar(hash.amount, 1);
          hash.amountLeft = await helper.formatFromDollar(hash.amountLeft, 1);
        } else if (hash.currency === 2) {
          // tx is added in EUR
          hash.amount = await helper.formatFromEuro(hash.amount, 1);
          hash.amountLeft = await helper.formatFromEuro(hash.amountLeft, 1);
        } else {
          // BackwardsCompatible
          hash.amount = await helper.formatFromDollar(hash.amount, 1);
          hash.amountLeft = await helper.formatFromDollar(hash.amountLeft, 1);
        }

        hash.notSuggested = !!bool;
      }
    },
    formatDate: function(date) {
      this.receipt.createdDate = date.toDateString();
    },
    deleteFile: function(key, uploadType) {
      if (uploadType === 1) {
        this.receipt.receiptImages.splice(key, 1);
      } else if (uploadType === 2) {
        this.receipt.proofOfPayments.splice(key, 1);
      } else if (uploadType === 3) {
        this.receipt.receiptDocuments.splice(key, 1);
      }
    },
    deleteCustomField: function(key) {
      this.receipt.customFields.splice(key, 1);
    },
    resetCategory: function(type) {
      if (type === 4) {
        if (!this.isEdit) {
          this.receipt.txHashId = null;
        } else {
          this.receipt.txHashId = null;
          this.receipt.txHash = {
            id: null,
            txHash: null
          };
        }
      }
      if(type === 1){
        this.receipt.vendorId = null;
      }
    },
    resetPage: function() {
      this.isPublic = true;
      this.isPrivate = false;
      this.receipt = {
        id: null,
        companyId: null,
        createdDate: null,
        vendorId: null,
        txHashId: null,
        receiptImages: [],
        proofOfPayments: [],
        receiptDocuments: [],
        receiptItems: [],
        amount: null,
        payment: {
          method: null, // 0 = Cash, 1 = Credit Card, 2 = Crypto, 3 = Cheque, 4 = Wire Transfer
          chainType: null, // 0 = BTC, 1 = ETH, 2 = BNB
          txHashId: null,
          reference: null,
        },
        customFields: [],
      };
      this.currency = 0; // 0 = AED, 1 = USD, 2 = EUR
    }
  },
  watch: {
    isPublic: {
      handler: function() {
        if (this.isPublic) {
          this.isPrivate = false;
          this.receipt.isPrivate = false;
        }
      },
      deep: true
    },
    isPrivate: {
      handler: function() {
        if (this.isPrivate) {
          this.isPublic = false;
          this.receipt.isPrivate = true;
        }
      },
      deep: true
    },
    currency: {
      handler: function(currency) {
        if (
                this.txFilters.amount !== this.receipt.amount ||
                this.txFilters.currency !== currency ||
                this.txFilters.createdDate !== this.receipt.createdDate            
        ) {   
          const amount = this.receipt.amount;
          this.receipt.amount = 0;
          this.receipt.amount = amount;
        }
      },
      deep: true
    },
    receipt: {
      handler: function(receipt) {        
        if(receipt.companyId && receipt.amount && receipt.createdDate !== null){
            if(this.txFilters.amount !== receipt.amount || this.txFilters.currency !== this.currency || this.txFilters.createdDate !== receipt.createdDate){              
              this.txFilters = {
                companyId: receipt.companyId,
                amount: receipt.amount,
                createdDate: receipt.createdDate,
                currency: this.currency
              };
              this.getFilteredTxHashes();
            }
        }        
        let receiptValid = false;
        // Cash, Credit Card, Cheque
        if (receipt.payment.method === 0 || receipt.payment.method === 1 || receipt.payment.method === 2 || receipt.payment.method === 4) {
          receiptValid = receipt.companyId !== null &&
              receipt.createdDate !== null &&
              receipt.vendorId !== null &&
              (receipt.amount !== null && receipt.amount !== 0 && receipt.amount !== '');
        }

        // Wire Transfer
        if (receipt.payment.method === 3) {
          receiptValid = receipt.companyId !== null &&
              receipt.createdDate !== null &&
              receipt.vendorId !== null &&
              receipt.payment.reference !== null &&
              receipt.payment.reference !== '' &&
              (receipt.amount !== null && receipt.amount !== 0 && receipt.amount !== '');
        }

        let itemsValid = false;
        if (receipt.receiptItems.length > 0) {
          for (let i = 0; i < receipt.receiptItems.length; i++) {
            const item = receipt.receiptItems[i];

            itemsValid = (item.amount !== null && item.amount !== '' && item.amount !== undefined) &&
                (item.expenseCategoryId !== null) &&
                (item.expenseId !== null);

            if(item.type === 1)
            {
              if(item.valuation === null || item.valuation === '')
              {
                itemsValid = false;
              }
                
            }
          }
        } else {
          itemsValid = true;
        }

        let customFieldsValid = false;
        if (receipt.customFields.length > 0) {
          for (let i = 0; i < receipt.customFields.length; i++) {
            const item = receipt.customFields[i];

            customFieldsValid = (item.name !== null && item.name !== '' && item.name !== undefined) &&
                (item.value !== '' && item.value !== null && item.value !== undefined);
          }
        } else {
          customFieldsValid = true;
        }

        this.isFormValid = receiptValid && itemsValid && customFieldsValid;
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
