<template>
  <div class="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <div>
          <div class="sm:border-gray-200">
            <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Confirm your account</h1>
            <p class="mt-1 text-base text-gray-500">We've send an activation email to: {{ email }}</p>
            <p class="mt-1 text-base text-gray-500">Please click the button in the email to activate your account. After activating your account, you can sign in.</p>
            <p class="mt-1 text-base text-gray-500">Not your email? <router-link :to="{name: 'Register'}" class="font-bold text-blue-500">Click here</router-link></p>
            <div v-if="loading" class="w-5 mx-auto">
              <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm.vue",
  data: () => ({
    email: null,
    loading: false,
  }),
  beforeMount: function() {
    // this.email = this.hideEmail(this.$route.query.email);
    this.email = this.$route.query.email;
  },
  methods: {
    hideEmail: function(email) {
      const splitted = email.split("@");
      let part1 = splitted[0];
      const avg = part1.length / 2;
      part1 = part1.substring(0, (part1.length - avg));
      const part2 = splitted[1];
      return part1 + "...@" + part2;
    },
  },
}
</script>

<style scoped>

</style>
