import axios from "axios";
import store from "@/store";

export default class BusinessService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    appendFormData(formData: FormData, dto: Record<string, any>) {
        formData.append('Name', dto.name);
        if (dto.about) {
            formData.append('About', dto.about);
        } else {
            formData.append('About', '');
        }
        if (dto.companyAddress) {
            formData.append('CompanyAddress', dto.companyAddress);
        } else {
            formData.append('CompanyAddress', '');
        }
        if (dto.companyZipcode) {
            formData.append('CompanyZipcode', dto.companyZipcode);
        } else {
            formData.append('CompanyZipcode', '');
        }
        if (dto.companyCity) {
            formData.append('CompanyCity', dto.companyCity);
        } else {
            formData.append('CompanyCity', '');
        }
        if (dto.companyCountry) {
            formData.append('CompanyCountry', dto.companyCountry);
        } else {
            formData.append('CompanyCountry', '');
        }
        if (dto.companyProvince) {
            formData.append('CompanyProvince', dto.companyProvince);
        } else {
            formData.append('CompanyProvince', '');
        }
        if (dto.legalName) {
            formData.append('LegalName', dto.legalName);
        } else {
            formData.append('LegalName', '');
        }
        if (dto.businessIdNo) {
            formData.append('BusinessIdNo', dto.businessIdNo);
        } else {
            formData.append('BusinessIdNo', '');
        }
        if (dto.taxRegistrationNumber) {
            formData.append('TaxRegistrationNumber', dto.taxRegistrationNumber);
        } else {
            formData.append('TaxRegistrationNumber', '');
        }
        if (dto.taxForm) {
            formData.append('TaxForm', dto.taxForm);
        } else {
            formData.append('TaxForm', '');
        }
        if (dto.industry) {
            formData.append('Industry', dto.industry);
        } else {
            formData.append('Industry', '');
        }
        if (dto.companyEmail) {
            formData.append('CompanyEmail', dto.companyEmail);
        } else {
            formData.append('CompanyEmail', '');
        }
        if (dto.companyPhoneNr) {
            formData.append('CompanyPhoneNr', dto.companyPhoneNr);
        } else {
            formData.append('CompanyPhoneNr', '');
        }
        if (dto.latitude) {
            formData.append('Latitude', dto.latitude);
        } else {
            formData.append('Latitude', '');
        }
        if (dto.longitude) {
            formData.append('Longitude', dto.longitude);
        } else {
            formData.append('Longitude', '');
        }

        return formData;
    }

    async createBusiness(dto: Record<string, any>) {
        let formData = new FormData();
        formData = this.appendFormData(formData, dto);
        formData.append('IsPrivate', dto.isPrivate);
        formData.append('UsesFourEyesPrinciple', dto.usesFourEyesPrinciple);

        if (dto.businessLicense) {
            formData.append('BusinessLicense', dto.businessLicense);
        } else {
            formData.append('BusinessLicense', '');
        }

        if (dto.socials && dto.socials.length > 0) {
            for (let i = 0; i < dto.socials.length; i++) {
                if (dto.socials[i].url !== null) {
                    formData.append(`Socials[${i}].Type`, dto.socials[i].type);
                    formData.append(`Socials[${i}].Url`, dto.socials[i].url);
                }
            }
        }

        if (dto.images.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.images.forEach(img => {
                formData.append(`Images`, img)
            });
        }
        if (dto.videos.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.videos.forEach(img => {
                formData.append(`Videos`, img)
            });
        }

        return await axios.post(`${process.env.VUE_APP_SERVICE}/company/register`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async editBusiness(dto: Record<string, any>) {
        let formData = new FormData();
        formData.append('Id', dto.id);
        formData = this.appendFormData(formData, dto);
        formData.append('IsPrivate', dto.isPrivate);
        formData.append('UsesFourEyesPrinciple', dto.usesFourEyesPrinciple);

        if (dto.socials && dto.socials.length > 0) {
            for (let i = 0; i < dto.socials.length; i++) {
                if (dto.socials[i].url !== null) {
                    formData.append(`Socials[${i}].Type`, dto.socials[i].type);
                    formData.append(`Socials[${i}].Url`, dto.socials[i].url);
                }
            }
        }

        if (dto.selectedLicense[0] && dto.selectedLicense[0] !== '') {
            formData.append('BusinessLicense.Url', dto.selectedLicense[0]);
        } else if (dto.businessLicense) {
            formData.append('BusinessLicense.File', dto.businessLicense);
            formData.append('BusinessLicense.Url', '');
        } else {
            formData.append('BusinessLicense.Url', '');
            formData.append('BusinessLicense.File', '');
        }
        // Add all existing receipt images
        const images = [];
        if (dto.selectedImages.length > 0) {
            for (let i = 0; i < dto.selectedImages.length; i++) {
                const file = {
                    file: null,
                    url: dto.selectedImages[i]
                }
                images.push(file);
            }
        }

        // Add new images
        if (dto.images.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.images.forEach(product => {
                const file = {
                    file: product,
                    url: null
                }
                images.push(file);
            });
        }

        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                if (images[i].url !== null) {
                    formData.append(`Images[${i}].File`, "");
                    formData.append(`Images[${i}].Url`, images[i].url);
                } else if (images[i].file !== null) {
                    formData.append(`Images[${i}].Url`, "");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Images[${i}].File`, images[i].file, images[i].file.name);
                }
            }
        }

        // Add all existing videos
        const videos = [];
        if (dto.selectedVideos.length > 0) {
            for (let i = 0; i < dto.selectedVideos.length; i++) {
                const file = {
                    file: null,
                    url: dto.selectedVideos[i]
                }
                videos.push(file);
            }
        }

        // Add new images
        if (dto.videos.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.videos.forEach(product => {
                const file = {
                    file: product,
                    url: null
                }
                videos.push(file);
            });
        }

        if (videos.length > 0) {
            for (let i = 0; i < videos.length; i++) {
                if (videos[i].url !== null) {
                    formData.append(`Videos[${i}].File`, "");
                    formData.append(`Videos[${i}].Url`, videos[i].url);
                } else if (videos[i].file !== null) {
                    formData.append(`Videos[${i}].Url`, "");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Videos[${i}].File`, videos[i].file, videos[i].file.name);
                }
            }
        }

        return await axios.post(`${process.env.VUE_APP_SERVICE}/company/update`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getAccountCompanies(page: number, pageSize: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/get-all-permissioned?page=${page}&pageSize=${pageSize}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getAccountEditCompanies(page: number, pageSize: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/get-all-permissioned?page=${page}&pageSize=${pageSize}&canEdit=true`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getAccountAdminCompanies() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/get-all-admin-account`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getOnlyAccountCompanies() {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/get-all-only-account`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getAllCompanies(page: number, pageSize: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/all?page=${page}&pageSize=${pageSize}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getCompanyById(id: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getOwnerIdWrite(id: number){
        return await axios.get(`${process.env.VUE_APP_SERVICE}/company/get-owner-id-write?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async createAccessToken(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/company/create-access-token`, dto,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
