<template>
  <div class="block mt-2">
    <div v-if="accounts && accounts.length > 0"
      class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Add to Account
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="max-w-lg mt-2 block">
          <select v-on:change="selectAccount($event)" v-model="accountId"
            class="w-full block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            <option selected :value="null">Select Account (optional)</option>
            <option v-for="(account, key) in accounts" :key="key" :value="account.id">{{ account.username }}:
              {{ account.email }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        First name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-2">
            <input type="text" v-model="employee.firstName" required placeholder="First name"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Middle name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-2">
            <input type="text" v-model="employee.middleName" required placeholder="Middle name"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Last name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-2">
            <input type="text" v-model="employee.lastName" required placeholder="Last name"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Id number
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-2">
            <input type="text" v-model="employee.idNumber" required placeholder="Id number"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Position Held
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-2">
            <input type="text" v-model="employee.positionHeld" required placeholder="Position held"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
    </div>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
      <div>
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Id card image
        </label>
      </div>

      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div v-if="employee.idCardUrl" class="inline-block min-w-full">
          <div class="max-w-lg mb-4 flex overflow-x-auto">
            <div class="w-56 mr-5 rounded-md">
              <img :src="employee.idCardUrl" v-on:click="toggleModal(employee.idCardUrl)" class="w-full h-36 rounded-md cursor-pointer">
              <button v-on:click="deleteFile(true)" class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div v-if="!imgLoading" class="space-y-1 text-center">
            <svg v-if="!file" xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-gray-400" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
            </svg>
            <div class="flex text-sm text-gray-600">
              <label v-if="!file"
                class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-600">
                <span>Upload ID card image</span>
                <input v-on:change="selectFile" accept="image/*" type="file" class="sr-only">
              </label>
              <div class="block">
                <div v-if="file" class="text-blue-600 font-medium block">
                  <div class="block">
                    {{ file.name }}
                    <img v-if="file.name !== ''" :id="`employee-idcardimage`" />
                    <button v-if="file.name !== ''" v-on:click="deleteFile()"
                      class="bg-red-500 text-white rounded mx-auto block px-2 mt-2 py-1">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal" />
    </div>
    <div class="mt-8">
      <div v-if="!isEdit" class="text-center mx-auto">
        <div v-if="isFormValid">
          <button v-if="!loading" v-on:click="createEmployee"
            class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Add
            Employee
          </button>
          <button v-else-if="loading"
            class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </span>
            Add Employee
          </button>
        </div>
        <button v-else
          class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
          Add Employee
        </button>
      </div>
      <div v-else class="text-center mx-auto">
        <div v-if="isFormValid">
          <button v-if="!loading" v-on:click="updateEmployee"
            class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Edit
            Employee
          </button>
          <button v-else-if="loading"
            class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </span>
            Edit Employee
          </button>
        </div>
        <button v-else
          class="group relative mx-auto md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
          Edit Employee
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeService from "../../../service/employee";
import ImgViewer from '@/components/modals/receipt/ImageViewer';

export default {
  name: "CreateEmployeeForm",
  components: {
    ImgViewer
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    selectType: {
      type: Number,
      required: false,
      default: null
    },
    employeeFilled: {
      type: Object,
      required: false,
      default: null
    },
    accounts: {
      type: Array,
      required: false,
      default: null
    },
  },
  data: () => ({
    accountId: null,
    employee: {
      firstName: null,
      middleName: null,
      lastName: null,
      idNumber: null,
      positionHeld: null,
      idCardUrl: null,
    },
    loading: false,
    isFormValid: false, // form validation
    showModal: false,
    imgUrl: null,
    imgLoading: false,
    file: null,
  }),
  mounted: function () {
    if (this.employeeFilled && this.isEdit)
      this.employee = this.employeeFilled;
    if (this.employee.ownerAccountId) {
      this.accountId = this.employee.ownerAccountId;
    }
  },
  methods: {
    createEmployee: async function () {
      this.loading = !this.loading;
      const service = new EmployeeService();
      const dto = {
        FirstName: this.employee.firstName,
        MiddleName: this.employee.middleName,
        LastName: this.employee.lastName,
        IdNumber: this.employee.idNumber,
        PositionHeld: this.employee.positionHeld,
      };

      if(this.file)
      {
        dto.IdCardFile = this.file;
      }

      if (this.accountId) {
        dto.OwnerAccountId = this.accountId;
      }

      await service.create(dto)
        .then(async (response) => {
          this.$notifications(
            'Employee Added',
            '',
            '',
            0,
            true
          );
        }).catch((e) => {
          const error = JSON.parse(e.request.response);
          this.$notifications(
            `Something Went Wrong Creating Employee`,
            `${error.message}. Please try again`,
            '',
            1,
            true
          );
        }).finally(() => {
          this.loading = !this.loading;

          this.employee = {
            firstName: null,
            middleName: null,
            lastName: null,
            idNumber: null,
            positionHeld: null,
          
          }
          this.file = null;
        });
    },
    updateEmployee: async function () {
      this.loading = !this.loading;
      const service = new EmployeeService();

      const dto = {
        Id: this.employee.id,
        FirstName: this.employee.firstName,
        MiddleName: this.employee.middleName,
        LastName: this.employee.lastName,
        IdNumber: this.employee.idNumber,
        PositionHeld: this.employee.positionHeld,
        IdCardUrl: this.employee.idCardUrl
      };

      if(this.file)
      {
        dto.IdCardFile = this.file;
      }

      await service.update(dto)
        .then(async (response) => {
          this.$notifications(
            'Employee updated',
            '',
            '',
            0,
            true
          );
        }).catch((e) => {
          const error = JSON.parse(e.request.response);
          this.$notifications(
            `Something Went Wrong updating Employee`,
            `${error.message}. Please try again`,
            '',
            1,
            true
          );
        }).finally(() => {
          this.loading = !this.loading;
        });
    },
    selectAccount: function ($event) {
      this.accountId = $event.target.value;
    },
    toggleModal: function (url = '') {
      this.imgUrl = url;
      this.showModal = !this.showModal;
    },
    deleteFile: async function (existingFile = false) {
      if(existingFile){
        this.employee.idCardUrl = null
      }else{
        this.file = null;
      }      
    },
    selectFile: async function (event) {
      console.log('selected file')
      this.file = null;
      const files = event.target.files;
      this.file = files[0];
      console.log(this.file)
      let reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById(`employee-idcardimage`).setAttribute('src', e.target.result);      
        
      },
      reader.readAsDataURL(files[0]);
    },    
  },
  watch: {
    employee: {
      handler: function (employee) {
        this.isFormValid = employee.firstName !== null && employee.lastName !== null && employee.idNumber !== null && employee.positionHeld !== null;
      },
      deep: true
    }
  }
}
</script>