<template>
<div id="auth">
  <router-view />
</div>
</template>

<script>
export default {
name: "Auth"
}
</script>

<style scoped>

</style>
